import React,{useContext, useState, useEffect} from 'react'
import { Context } from "../contexts/Store"


function PlotMarkerMP(props) {

  const [divStyle, setDivStyle] = useState()
  const [state, dispatch] = useContext(Context)

  let thisYPos
  let thisHeightScale

  if(props.containerType == "Site"){
    let a = props.posY * 71.0
    thisYPos = a + 14.5 
    thisHeightScale = 71.0
  }else{
    thisYPos = props.posY * 100
    thisHeightScale = 100.0

  }
  
  let thisWidth = Number((props.markerWidth * 100.0).toFixed(1))
  let thisHeight = Number((props.markerHeight * thisHeightScale).toFixed(1))
  let thisFontSize = Number((props.markerWidth * 40).toFixed(1))

  const newDivStyle = {
    top: thisYPos + '%',
    left: props.posX * 100.0 + '%',
    width: thisWidth + '%',
    height: thisHeight + '%',
    backgroundColor: props.plotColour,
    fontSize: thisFontSize + 'rem'
  }

 useEffect(()=>{
    setDivStyle(newDivStyle)
  },[props.posX, props.posY])


 function PlotMarkerClicked(){
  if(!state.isPanning)
    dispatch({ type: "showPlotPreview", data: { "plotid": props.plotId, "markerId": props.plotNum }})
    
  }

  return (
    <button key={props.plotNum} id={props.plotNum} className="plotMarkerMP" onClick={PlotMarkerClicked} style={divStyle}>{props.plotNum}</button>
    )
  }

export default PlotMarkerMP
