import { useEffect, useContext } from 'react'
import { Context } from '../contexts/Store'
import axios from 'axios'

function useThinkHubData() {
  const [state, dispatch] = useContext(Context)

  const BASE_URL = 'https://www.think-hub.co.uk/api'

  const endPoints = [
    {
      name: 'devName',
      url: `${BASE_URL}/v1/GetStartupInformation/${state.devId}`,
    },
    {
      name: 'clientName',
      url: `${BASE_URL}/v1/GetClientInfo/${state.devId}`,
    },
    { name: 'devStyle', url: `${BASE_URL}/v1/GetStyle/${state.devId}/true` },
    {
      name: 'plotStatuses',
      url: `${BASE_URL}/v1/GetPlotStatuses/${state.devId}/true`,
    },
    { name: 'plots', url: `${BASE_URL}/v1/GetPlots/${state.devId}/true` },
    {
      name: 'menuItemsRaw',
      url: `${BASE_URL}/v1/GetMenuItems/${state.devId}/`,
    },
    {
      name: 'plotContainers',
      url: `${BASE_URL}/v1/GetPlotsContainer/${state.devId}/true`,
    },
  ]

  function getAllData(endPoints) {
    Promise.all(endPoints.map((endPoint) => getData(endPoint))).then(function (
      results
    ) {
      //console.log("Got all data thinkhub", results);
      return results
    })
  }

  async function getData(endPoint) {
    try {
      const response = await axios.get(endPoint.url, {
        headers: { 'Registration-Key': state.devKey },
      })
      dispatch({
        type: `SET_API_DATA`,
        key: endPoint.name,
        payload: response.data,
      })
      dispatch({ type: `SET_API_LOADED`, key: endPoint.name })
      return response
    } catch (error) {
      dispatch({ type: `SET_API_ERROR`, key: endPoint.name })
    }
  }

  useEffect(() => {
    if (state.devId) {
      getAllData(endPoints)
    }
  }, [state.devId])

  //check all is loaded
  useEffect(() => {
    if (state.loaded.length === endPoints.length) {
      dispatch({ type: `SET_API_ALL_LOADED` })
    }
  }, [state.loaded])

  return true
}

export default useThinkHubData
