import React, { useRef, useContext } from "react"
import IdleTimer from 'react-idle-timer'
import { useNavigate } from "react-router-dom"
import { Context } from "../contexts/Store"

function IdleTimerContainer() {

  const idleTimerRef = useRef(null)
  const [state, dispatch] = useContext(Context)
  const navigate = useNavigate()
  
  const handleAction = ()=> {
    //console.log('handle action')
    idleTimerRef.current.start()
  }

  const timeout = () => {
    //console.log('timeout')
    dispatch({
      type: "turnOnLightsAttractor"
    })
    dispatch({
      type: "clearFavPlots"
    })
    navigate('/')
  }

  return (
    <div>
      <IdleTimer ref={idleTimerRef} startOnMount={false} eventsThrottle={1000} onAction={handleAction} stopOnIdle={true} timeout={5 * 60000} onIdle={timeout}></IdleTimer>
    </div>
  )
}

export default IdleTimerContainer