import React, { useContext } from 'react'

import { Context } from "../contexts/Store"
import { resetFilter } from '../contexts/Filters'

import '../assets/styles/components/ActiveFilters.scss'
import { ReactComponent as IconTick } from '../assets/img/tick.svg'
import { ReactComponent as IconFilters } from '../assets/img/filter.svg'

function ActiveFilters() {

  const [state, dispatch] = useContext(Context)

  return (
    <section className="ActiveFilters">
      <ul className="ActiveFilters__Inner">
        <li className="ActiveFilters__Item ActiveFilters__Item--count">
          <span className="ActiveFilters__Icon">
            <IconFilters />
          </span>
          {state.filteredHomes.length} 
          {state.filteredHomes.length == 1 ? ' home' : ' homes'}
        </li>
        {state.filters.map((item, i) => {
          if (item.active && item.name !== 'plotStatus') {
            return (
              <li
                className="ActiveFilters__Item ActiveFilters__Item--filter"
                key={i}
                onClick={() => resetFilter(state, dispatch, i)}
              >
                <IconTick />
                {item.displayName}
              </li>
            )
          }
        })}
      </ul>
    </section>
  )
}

export default ActiveFilters
