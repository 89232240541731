import React from 'react'
import { Link } from 'react-router-dom'

import '../assets/styles/components/Button.scss'
import { ReactComponent as IconArrow } from '../assets/img/arrow-right.svg'
import { ReactComponent as IconChevronDown } from '../assets/img/chevron-down.svg'

function Button({ variant = 'primary', size = 'md', to, onClick, className, ...props }) {

  let icon = props.icon
  switch(icon) {
    case 'arrow':
      icon = <IconArrow />
      break

    case 'chevron-down':
      icon = <IconChevronDown />
      break
  }

  if (to) {
    return (
      <Link
        to={to}
        role="button"
        className={`Button Button--${variant} Button--${size} ${className}`}
        onClick={onClick}
      >
          <span className="Button__Text">{props.children}</span>
          {icon &&
            <span className="Button__Icon">{icon}</span>
          }
      </Link>
    )
  } else {
    return (
      <button
        className={`Button Button--${variant} Button--${size} ${className}`}
        onClick={onClick}
      >
          <span className="Button__Text">{props.children}</span>
          {icon &&
            <span className="Button__Icon">{icon}</span>
          }
      </button>
    )
  }
}

export default Button
