import React, { useState, useContext } from 'react'
import { Context } from '../contexts/Store'
import { filteredCountByType } from "../helpers/utilities"

import PageHeader from '../components/PageHeader'
import Overlay from '../components/Overlay'
import Gallery from '../components/Gallery'
import Photos from '../components/Photos'
import Cards from '../components/Cards'
import Flag from '../components/Flag'
import Panel from '../components/Panel'
import Outro from '../components/Outro'
import Button from '../components/Button'

import { ReactComponent as IconPlay } from '../assets/img/icon-play.svg'

function Brochure() {

  const [state, dispatch] = useContext(Context)

  return (
    <>
      <PageHeader />
      <Overlay imageID="1">
        <h2>A More Natural Way of Life</h2>
        <p>With its integral cycleways and pedestrian links, a trim trail, interconnected green spaces, grassland and woodland bordering the development, Wilton Park offers not just a desirable place to call home, but a natural environment to explore, enjoy and flourish – yet one that’s easily connected to both Beaconsfield and the Capital.</p>
      </Overlay>
      <Overlay imageID="2" align="left">
        <h2>Inspired by the legacy of its surroundings</h2>
        <a href="#">Watch the film <IconPlay /></a>
      </Overlay>
      <Gallery />
      <Photos />
      <Cards title="A new community in the making" />
      <Flag imageID="1">
        <h3>Beaconsfield - a characterful market town</h3>
        <p>With its backdrop of the Chiltern Hills, surrounded by beautiful countryside and stunning woodland, Beaconsfield is a prosperous market town with two distinctive characters. The Old Town enjoys a quintessentially English feel with
          an eclectic mix of shops, boutiques, tea-rooms, restaurants, traditional pubs and a bustling Tuesday market.</p>
        <div style={{ marginTop: '40px' }}>
          <Button variant="secondary" to="/local-area">Explore the local area</Button>
        </div>
      </Flag>
      <Flag imageID="2" align="reverse">
        <h3>From boutiques to bakeries, florists to farmers’ markets.</h3>
        <p>With its backdrop of the Chiltern Hills, surrounded by beautiful countryside and stunning woodland, Beaconsfield is a prosperous market town with two distinctive characters. The Old Town enjoys a quintessentially English feel with an eclectic mix of shops, boutiques, tea-rooms, restaurants, traditional pubs and a bustling Tuesday market.</p>
      </Flag>
      <Flag imageID="3">
        <h3>Where exquisite taste meets impeccable style</h3>
        <p>With its backdrop of the Chiltern Hills, surrounded by beautiful countryside and stunning woodland, Beaconsfield is a prosperous market town with two distinctive characters. The Old Town enjoys a quintessentially English feel with
          an eclectic mix of shops, boutiques, tea-rooms, restaurants, traditional pubs and a bustling Tuesday market.</p>
      </Flag>
      <Panel imageID="1">
        <h2>Some of the best schools on your doorstep</h2>
        <p>A magnet for those wanting the very best educational opportunities for their children, Beaconsfield is renowned for the breadth of choice and calibre of schooling available. Importantly, Buckinghamshire is also one of the few counties that supports a selective grammar school system including Davenies, Wycombe Abbey, Godstowe and Caldicott.</p>
      </Panel>
      <Cards type="schools" />
      <Overlay imageID="3" align="center">
        <h2>Nestled in an area of outstanding natural beauty</h2>
        <p>With its landscape of beautiful rolling countryside, green hills, sparkling chalk streams and acres of ancient woodland, the Chiltern Hills are the perfect backdrop to relax and unwind. And with a wide choice of sedate walks, relaxing cycles or adventurous hikes, living at Wilton Park means you can enjoy both far-reaching views and natural beauty up-close – all near to home.</p>
      </Overlay>
      <Panel imageID="2">
        <h2>Connected to the city and beyond</h2>
        <p>Despite its semi-rural surroundings, Beaconsfield is well connected. Wilton Park is less than 1.5 miles from Beaconsfield station which offers frequent, direct services via Chiltern Railways to London Marylebone in as little as 23 minutes. From here, there’s direct access to the London Underground network. Road connections are just as good; junction 2 of the M40 is less than a mile away, and the M40/M25 interchange within five miles.</p>
      </Panel>
      <Cards type="locations" />
      <Outro />
    </>
  )
}

export default Brochure