import React, { useContext, useState, useEffect, useRef } from "react"
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch"
import { DragDropContainer } from 'react-drag-drop-container'
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

import { Context } from "../contexts/Store"

import GetSpinDataFrame from "../components/GetSpinDataFrame"
import PlotPreview from "../components/PlotPreview"
import MapControl from "../components/MapControl"
import Alert from "../components/Alert"

import '../assets/styles/components/SiteplanSpin.scss'
import { ReactComponent as VrPersonIcon } from '../assets/img/vr-person-icon.svg'

let lastClientX = 0
let lastClientY = 0
let enableSpin = true
let doOnceFlag = false
let isMobile = false


let lowRes = ""
let hiRes = ""

let lastrecordedSpinPosition = 0
let lastrecordedTiltPosition = 0

function SiteplanSpin(){
  
  let id = useParams().id

  let spin = {
    rotation: 0,
    tilt: 0,
    frameNumber :4,
    frameNumberPan :0,
    frameNumberTilt: 0,
    framePanAngle :0,
    steppedRotationAngle :0,
    steppedTiltAngle : 0
  }
  
  let mouseIsDown = false
  let spinId = useParams().spinId

  const [state, dispatch] = useContext(Context)
  const navigate = useNavigate()
  const location = useLocation()

  const compassOffset = 0
  let t2
  const hiResTimer = 200
  let moreThanOneTouch = false
  let interactiveTimer

  const transformComponentRef = useRef(null)
  const [spinImage, setSpinImage] = useState()
  const [frameNumberState, setFrameNumber] = useState(spin.frameNumber)
  const [disablePan, setDisablePan] = useState(true)
  const [scale, setScale] = useState(1)
  const [tiltAngle, setTiltAngle] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const [thisSpinConfig, setThisSpinConfig] = useState(false)

  const { isMobileDevice, spinConfigData, spinAssetsForDevice, 
    selectedPlotMarkerId, isPreviewPanelOpen, isFiltersOpen, 
    devSpinPath, devId, devName, isTabletDevice, filteredHomes } = state
  
  useEffect(()=>{
    isMobile = isMobileDevice
    if(isMobileDevice){
      document.documentElement.style.setProperty("--frame-width", 800 )
    }else{
      document.documentElement.style.setProperty("--frame-width", 2000 )
    }
  },[isMobileDevice])
  
  useEffect(()=>{
    spinConfigData.every(function (arrayItem){
      if(arrayItem.spinId == id){
        setThisSpinConfig(arrayItem.spinConfigData)
        return false
      }
      return true
  })
},[spinConfigData])

useEffect(()=>{
  if(thisSpinConfig){
    spin.rotation = parseFloat(thisSpinConfig.startRotation)
    spin.tilt = parseFloat(thisSpinConfig.startTilt)
    lastrecordedSpinPosition = spin.rotation
    lastrecordedTiltPosition = spin.tilt
    DisplaySpinFrame()
  }
},[thisSpinConfig])

useEffect(()=>{
  CenterView()
},[location])

useEffect(()=>{
  if(spinAssetsForDevice){
    lowRes = spinAssetsForDevice.lowRes
    hiRes = spinAssetsForDevice.hiRes
    DisplaySpinFrame()
  }
},[spinAssetsForDevice])

useEffect(()=>{
  setIsMounted(true)
  window.scrollTo(0, 0)
  dispatch({ type: "isVrpersonActive", data: false})
  
  window.addEventListener('mousedown', (event) => {
    MouseDown(event)
  })
  
  window.addEventListener('mousemove', (event) => {
    MouseMoved(event)
  })

  window.addEventListener('mouseup', (event) => {
    RecordSpinPosition(event)
  })

  window.addEventListener('resize', (event) => {
    CenterView()
  })

  document.addEventListener('touchstart', (event) => {
    touchDown(event)
  })

  document.addEventListener('touchend', (event) => {
    RecordSpinPosition(event)
  })

  document.addEventListener('touchmove', (event) => {
    TouchDidMove(event)
  })

  return()=>{
    setIsMounted(false)
    clearAllBodyScrollLocks()
    window.removeEventListener('mousedown', (event) => {
      MouseDown(event)
    })
 
    window.removeEventListener('mousemove', (event) => {
      MouseMoved(event)
    })
 
    window.removeEventListener('mouseup', (event) => {
      RecordSpinPosition(event)
    })
 
    window.removeEventListener('resize', (event) => {
      CenterView()
    })
 
    document.removeEventListener('touchstart', (event) => {
      touchDown(event)
    })
 
    document.removeEventListener('touchend', (event) => {
      RecordSpinPosition(event)
    })
 
    document.removeEventListener('touchmove', (event) => {
      TouchDidMove(event)
    })
  }
},[])

useEffect(() => {
  if(transformComponentRef.current)
  disableBodyScroll(transformComponentRef.current)

}, [transformComponentRef.current])

useEffect(()=>{
  zoomToPlotMarker()

},[selectedPlotMarkerId])

useEffect(()=>{
  if(!isPreviewPanelOpen)
  CenterView()

},[isPreviewPanelOpen])

useEffect(() => {

  if(scale >1){
    enableSpin = false
    setDisablePan(false)
  }else{
    if(isPreviewPanelOpen){
      dispatch({ type: "closePlotPreview" })
    }
    enableSpin = true
    setDisablePan(true)
  }
},[scale] )

useEffect(()=>{
  if(isPreviewPanelOpen){
    setDisablePan(false)
    enableSpin = false
  }else{
    setDisablePan(true)
    enableSpin = true
  }
},[isPreviewPanelOpen, isFiltersOpen])

function MouseDown(event){
  if(enableSpin){
    mouseIsDown = true
    lastClientX = event.clientX
    lastClientY = event.clientY
  }
}

function MouseMoved(event) {
  let elementUnderMouse = document.elementFromPoint(event.clientX, event.clientY)

  let elementUnderMouseId = ""
  if (elementUnderMouse) {
    elementUnderMouseId = elementUnderMouse.id.toString()
  }
  
  if(mouseIsDown && enableSpin && elementUnderMouseId ==='spin'){
    let mousePositionX = (event.clientX - lastClientX) * .2
    let mousePositionY = (event.clientY - lastClientY) * .3
    SetAngles(mousePositionX, mousePositionY)
  }

}

function RecordSpinPosition(event) {
  if(enableSpin){
    mouseIsDown = false
    lastrecordedSpinPosition = spin.rotation
    lastrecordedTiltPosition = spin.tilt
  }
}

function checkForNumberOfTouches(event) {
  if(event.touches.length > 1){
    moreThanOneTouch = true
  }else{
    moreThanOneTouch = false
  } 
}

function touchDown(event){
  mouseIsDown = false
  checkForNumberOfTouches(event)
  
  if(enableSpin && !moreThanOneTouch){
    lastClientX = event.touches[0].clientX
    lastClientY = event.touches[0].clientY
  }
}

function TouchDidMove(event) {
  mouseIsDown = false
  if (enableSpin && !moreThanOneTouch){
    let touchPositionX = (event.changedTouches[0].clientX - lastClientX) * .2
    let touchPositionY = (event.changedTouches[0].clientY - lastClientY) * .3
    SetAngles(touchPositionX, touchPositionY)
  }
}

function SetAngles(inputPositionX, inputPositionY){
  spin.rotation = (lastrecordedSpinPosition + inputPositionX)%360
  let tiltLimit = (lastrecordedTiltPosition + inputPositionY)%360
  
  if(isMobile){
    spin.tilt = 30
  }else{
    spin.tilt = Math.min(Math.max(parseInt(tiltLimit), 30), 90)
  }
  setTiltAngle(spin.tilt)
  //console.log('current rot: ' + spin.rotation + ' tilt: ' + spin.tilt) //keep for debug set up [rich 24012022]
  DisplaySpinFrame()
}

function DisplaySpinFrame(){ 
  clearInterval(t2)
  if(lowRes !="" && hiRes !="" ){
    t2 = setInterval(function() {displayHiResImageAfterDelay(); }, hiResTimer)
    const originalFrameNumber = spin.frameNumber
    GetFrameNumber()
    
    if(spin.frameNumber !=originalFrameNumber){
      setSpinImage(`${devSpinPath}${devId}/${id}/` + lowRes + framePath())
      drawMarkers()
    }
  }
}

function GetFrameNumber(){
  //USE THIS FOR FULL 630 FRAMES
  let rotationSteps = 90
  let n = (rotationSteps * parseInt(-spin.rotation)/360 )
  spin.steppedRotationAngle = (360 * n /rotationSteps)
  spin.framePanAngle = (parseInt(spin.steppedRotationAngle) + 360 )%360
  spin.frameNumberPan = rotationSteps* parseInt(spin.framePanAngle)/360

  let tiltIntervals = 7
  let m = (tiltIntervals * parseInt(spin.tilt)/rotationSteps)
  
  spin.steppedTiltAngle = m* (rotationSteps/tiltIntervals)
  spin.frameNumberTilt = ( 7 * ( 1 - (parseInt(spin.steppedTiltAngle)/90.0) ))
  spin.frameNumber = (parseInt(spin.frameNumberPan) * 7) + parseInt(spin.frameNumberTilt)
}
 
function drawMarkers(){
  setFrameNumber(parseInt(spin.frameNumber))
}

function framePath(){
  let frameNumberString = spin.frameNumber.toString().padStart(2, "0")
  return '/spin_'+frameNumberString + '.jpg'
}

function displayHiResImageAfterDelay(){
  clearInterval(t2)
  setSpinImage(`${devSpinPath}${devId}/${id}/` + hiRes + framePath())
  drawMarkers()
}

function ZoomOut(){
  if(scale > 1){
    const factor = Math.log((scale -1.0) / scale)
    transformComponentRef.current.zoomOut(-factor, 300)
    setScale(scale => scale - 1)
  }
}

function ZoomIn(){
  if(scale < 4){
    const factor = Math.log((scale +1.0) / scale)
    transformComponentRef.current.zoomIn(factor, 300)
    setScale(scale => scale + 1)
  }
}

function CenterView(){
  if(transformComponentRef.current !=null){
    transformComponentRef.current.centerView(1, 1000, 'easeOut')
    setScale(1)
  }
}

function zoomToPlotMarker() {
  if(selectedPlotMarkerId){
    let thisMarker = document.getElementById(selectedPlotMarkerId)
    
    if(transformComponentRef.current !=null){
      let zoomToScale = 2
      if(scale >2 )
      zoomToScale = scale

      transformComponentRef.current.zoomToElement( thisMarker , zoomToScale, 1500, 'easeOut')
    }
  } 
}

function StartInteractiveTimeout(){
  clearInterval(interactiveTimer)
  interactiveTimer = setInterval(function() {EnableMarkerInteraction(); }, 200)
}

function EnableMarkerInteraction(){
  clearInterval(interactiveTimer)
  dispatch({ type: "isPanning", data: false})
}

function drop(event) {
  if(!doOnceFlag){
    doOnceFlag = true
    navigate("/exterior-tour/current/" + event.dropData, { state: { previousPath: location.pathname } })
  }
  dispatch({ type: "isVrpersonActive", data: false })
}

function drag(event){
  dispatch({ type: "isVrpersonActive", data: true})
  setDisablePan(false)
  enableSpin = false
}

function dragEnded(event){
  doOnceFlag = false
  dispatch({ type: "isVrpersonActive", data: false})
  if(scale >1){
    enableSpin = false
    setDisablePan(false)
  }else{
    enableSpin = true
    setDisablePan(true)
  }
}

  return (
    <>
      <Helmet>
        <title>{`Siteplan - ${devName.name}`}</title>
      </Helmet>


      <Outlet />
      {isMounted && (
        <>
      <div className="Masterplan">
        <motion.div
          className={'Masterplan__Map Masterplan__Map--spin'}
          key={'MainWrapper'}
          animate={{
            width: isPreviewPanelOpen && !isTabletDevice ? "70vw" : "100vw"
          }}
           onAnimationComplete={isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 40 }
          }}
        >
          <TransformWrapper
            ref={transformComponentRef}
            initialScale={scale}
            maxScale={4}
            limitToBounds={true}
            onZoomStop={(e) => {
                setScale(e.state.scale)
            }}
            panning ={{disabled: disablePan}} 
            onPanning={(e)=>{
              dispatch({ type: "isPanning", data: true})
            }}
            onPanningStop={(e)=>{
              StartInteractiveTimeout()
            }}
            centerOnInit
            onZoom={(e) => {
              setScale(e.state.scale)
            }}
          >
            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
              <>
                {!isPreviewPanelOpen && (
                  <>
                    <div className="Masterplan__Controls">
                    <MapControl variant="zoom-in" onClick={ZoomIn} title="Zoom in" disabled={scale === 4} />
                      <MapControl variant="zoom-out" onClick={ZoomOut} title="Zoom out" disabled={scale <= 1} />
                      <MapControl variant="center-map" onClick={CenterView} title="Center map" />
                    </div>
                  </>
                )}
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <div className="zoomContainer" id="spin">
                    <img src={spinImage} alt={spinImage}/>
                    <GetSpinDataFrame frameNumber = {frameNumberState} spinAssetId = {id} scale={scale} tilt={tiltAngle}/>
                  </div>
                </TransformComponent>
            
                {filteredHomes.length <= 0 && (
                  <Alert variant="floating">No homes match the chosen filters.</Alert>
                )}
              </>
            )}
          </TransformWrapper>
        </motion.div>
        <PlotPreview />
      </div>

      <DragDropContainer
        targetKey="vr_marker"
        onDrop={drop}
        onDrag={drag}
        onDragEnd={dragEnded}
      >
        <div className="EnableStreetview">
          <span className="EnableStreetview__Icon">
              <VrPersonIcon />
          </span>
          <span className="EnableStreetview__Label">Street View</span>
        </div>
      </DragDropContainer>
      </>
      )}
    </> 
  )
}

export default SiteplanSpin