import React, { useContext, useState  } from 'react'
import { useInView } from 'react-intersection-observer'

import { Context } from "../contexts/Store"

import Button from './Button'

import '../assets/styles/components/Outro.scss'
import image from '../assets/img/outro.jpg'

function Outro() {

  const [state] = useContext(Context)
  const logo = state.devStyle.images.additionalLogoUrl

  const { ref, inView } = useInView({
    threshold: 0.25
  })

  return (
    <footer ref={ref} className={`Outro animate ${inView ? 'active' : ''}`}>
      <div className="Outro__row">
        <div className="Outro__content">
          <h2>Created for that sense of arrival</h2>
          <p>At Bewley Homes we have long prided ourselves on attention to detail. The elevations to each new home have been designed to create an engaging street scene and a true sense of arrival.</p>
          <Button variant="secondary" to="/homes">Find your new home</Button>
        </div>
        <div className="Outro__image">
          <img src={image} alt="" />
        </div>
      </div>
    </footer>
  )
}

export default Outro
