import React from "react"
import ActionButton from "./ActionButton"
import Fancybox from "../components/Fancybox"

function FloorplanGalleryButton({ floorplans }) {
  return (
    <>
      {floorplans.length > 0 && (
        <Fancybox
          options={{
            Toolbar: false,
            closeButton: "outside"
          }}
        >
          {floorplans.map((image, i) => {
            if (i === 0) {
              return (
                <ActionButton
                  icon="floorplan"
                  data-fancybox="floorplans"
                  data-caption={image.caption}
                  data-src={image.fileUrl}
                  key={image.assetId}
                >
                  Floorplans
                </ActionButton>
              )
            } else {
              return (
                <a
                  data-fancybox="floorplans"
                  style={{ display: "none" }}
                  href={image.fileUrl}
                  data-caption={image.caption}
                  key={image.assetId}
                ></a>
              )
            }
          })}
        </Fancybox>
      )}
    </>
  )
}

export default FloorplanGalleryButton
