import {
  fromPrice,
  toPrice,
  getStatusID,
  getStatus
} from "../helpers/utilities"
import plotsToTypes from "../helpers/plotsToTypes"

export function setupFilters(state, dispatch) {
  let prices = []
  let bedrooms = []
  let houseTypes = []
  let floors = []
  let allCustomFilters = []
  let filtersList = []

  if (state.plots && state.plotStatuses) {
    // Loop through available homes to retrieve applicable filter values
    let homes = state.plots.filter((el) => {
      if (el.plotStatusId !== getStatusID(state.plotStatuses, "Hidden")) {
        if (el.plotStatusId === getStatusID(state.plotStatuses, "Available")) {
          prices.push(el.price)
          bedrooms.push(el.plotType.numberOfBeds)
          houseTypes.push(el.plotType.name)

          if (el.floorData) {
            floors.push(el.floorData.floorName)
          }

          if (el.customFields) {
            el.customFields.filter((cf) => {
              if (cf && cf.dataFilterType !== null) {
                allCustomFilters.push(cf)
                return cf
              } else {
                return null
              }
            })
          }

          return el
        }
      }
      return null
    })

    // Find the min and max price of all homes
    let maxPrice = toPrice(prices)
    let minPrice = fromPrice(prices)

    // Get number of bedrooms
    let uniqueBedrooms = [...new Set(bedrooms)] //get unique
    let orderedBedrooms = uniqueBedrooms.sort((a, b) => a - b)

    // Get unique floor numbers
    let uniqueFloors = [...new Set(floors)]
    let orderedFloors = uniqueFloors.sort((a, b) => a - b)

    // Get house types
    let uniqueHouseTypes = [...new Set(houseTypes)]
    let orderedHouseTypes = uniqueHouseTypes.sort((a, b) => a - b)

    // Add values to price filter
    if (!isFinite(minPrice) && !isFinite(maxPrice)) {
      // No price data therefore don't add the filter
    } else if (minPrice === maxPrice) {
      // No price data therefore don't add the filter
    } else {
      filtersList.push({
        id: 1,
        name: "price",
        displayName: "Price Range",
        type: "range",
        values: [minPrice, maxPrice],
        selectedValues: [minPrice, maxPrice]
      })

      let newRangeLabels = [...state.rangeLabels]
      newRangeLabels[1] = [minPrice, maxPrice]

      dispatch({
        type: "setRangeLabels",
        data: newRangeLabels
      })
    }

    // Add bedrooms values
    if (orderedBedrooms.length > 1) {
      let bedroomsValues = []
      orderedBedrooms.forEach(function (value) {
        let bedLabel = value === 1 ? `Studio/${value} bed` : `${value} beds`
        bedroomsValues.push({
          label: bedLabel,
          value: value,
          isChecked: false
        })
      })
      filtersList.push({
        id: 2,
        name: "bedrooms",
        displayName: "Number of beds",
        type: "checkbox",
        values: bedroomsValues
      })
    }

    // Add floors values
    if (orderedFloors.length > 1) {
      let floorValues = []
      orderedFloors.forEach(function (value) {
        floorValues.push({
          label: value,
          value: value,
          isChecked: false
        })
      })
      filtersList.push({
        id: 3,
        name: "floor",
        displayName: "Floor",
        type: "checkbox",
        values: floorValues
      })
    }

    // Add types values
    if (orderedHouseTypes.length > 1) {
      let houseTypesValues = []
      orderedHouseTypes.forEach(function (value) {
        houseTypesValues.push({
          label: value,
          value: value,
          isChecked: false
        })
      })

      filtersList.push({
        id: 4,
        name: "houseTypes",
        displayName: "Property Type",
        type: "checkbox",
        values: houseTypesValues
      })
    }

    // Add listing statuses
    if (state.plotStatuses) {
      let plotStatusValues = []
      let excludedStatuses = ["Hidden"]

      state.plotStatuses.map((item) => {
        if (!excludedStatuses.includes(item.name)) {
          const isChecked = item.name === "Available" ? true : false
          plotStatusValues.push({
            label: item.name,
            value: item.name,
            isChecked: isChecked,
            color: item.color
          })
        }
      })

      // Sort values alphabetically
      plotStatusValues
        .sort((a, b) => {
          if (!a.label) return

          let aVal = a.label.toLowerCase(),
            bVal = b.label.toLowerCase()

          return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
        })

        .sort(function (a, b) {
          var textA = a.label.toUpperCase()
          var textB = b.label.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })

      filtersList.push({
        id: 5,
        name: "plotStatus",
        displayName: "Status",
        type: "checkbox",
        values: plotStatusValues,
        active: true
      })
    }

    // Add custom fields values
    let customFiltersIDs = [
      ...new Set(allCustomFilters.flatMap(({ id }) => id))
    ].sort()
    customFiltersIDs.forEach(function (id) {
      let field = allCustomFilters.filter((cf) => {
        if (cf.id === id) {
          return cf
        } else {
          return null
        }
      })

      // Get unique field values
      let uniqueValues = [
        ...new Set(field.flatMap(({ fieldValue }) => fieldValue))
      ].sort()

      // Add checkbox fields
      if (field[0].dataFilterType === 2) {
        let values = []
        uniqueValues.forEach(function (value) {
          values.push({
            label: value,
            value: value,
            isChecked: false
          })
        })

        filtersList.push({
          id: id,
          name: field[0].displayName,
          displayName: field[0].displayName,
          type: "checkbox",
          values: values
        })

        // Add boolean fields
      } else if (field[0].dataFilterType === 3) {
        let values = []
        uniqueValues.forEach(function (value) {
          if (value === "true" || value === "True") {
            values.push({
              label: "Yes",
              value: value,
              isChecked: false
            })
          }
        })

        filtersList.push({
          id: id,
          name: field[0].displayName,
          displayName: field[0].displayName,
          type: "checkbox",
          values: values
        })
      }
    })

    // Set available filters
    dispatch({
      type: "setFilters",
      data: filtersList
    })

    // Set available homes
    dispatch({
      type: "setAvailableHomes",
      data: homes
    })

    dispatch({
      type: "setFilteredHomes",
      data: homes
    })
  }
}

export function runFilters(state, dispatch) {
  let filteredResults = state.plots.filter((el, index) => {
    let active = true // default to show

    if (state.filters) {
      state.filters.forEach(function (filter) {
        if (filter.type === "range") {
          //range
          let itemValue = parseInt(el[filter.name])

          if (
            (filter.selectedValues[0] <= itemValue &&
              filter.selectedValues[1] >= itemValue) ||
            !el.price
          ) {
          } else {
            active = false
          }
        } else if (filter.type === "checkbox") {
          //checkbox

          //get the filter values from filter into an array
          let filterValues = []
          filter.values.forEach(function (val) {
            if (val.isChecked) {
              filterValues.push(val.value)
            }
          })

          if (filter.name === "bedrooms") {
            if (filterValues && filterValues.length) {
              if (!filterValues.includes(el.plotType.numberOfBeds)) {
                active = false
              }
            }
          } else if (filter.name === "floor") {
            if (filterValues && filterValues.length) {
              if (!filterValues.includes(el.floorData.floorName)) {
                active = false
              }
            }
          } else if (filter.name === "houseTypes") {
            if (filterValues && filterValues.length) {
              if (!filterValues.includes(el.plotType.name)) {
                active = false
              }
            }
          } else if (filter.name === "plotStatus") {
            if (filterValues && filterValues.length) {
              const name = getStatus(
                state.plotStatuses,
                "id",
                el.plotStatusId
              ).name
              if (!filterValues.includes(name)) {
                active = false
              }
            }
          } else {
            //custom filters

            if (filterValues && filterValues.length) {
              //get this custom field value
              let customFieldValue = null
              if (el.customFields && el.customFields.length) {
                el.customFields.forEach(function (customField) {
                  if (customField.id === filter.id) {
                    customFieldValue = customField.fieldValue
                  }
                })
              }

              if (filterValues && filterValues.length) {
                if (!filterValues.includes(customFieldValue)) {
                  // found element
                  active = false
                }
              }
            }
          }
        }
      })
    }
    return active
  })

  dispatch({
    type: "setFilteredHomes",
    data: filteredResults
  })

  dispatch({
    type: "setTypes",
    data: plotsToTypes(filteredResults, state.plotStatuses)
  })
}

export function resetFilter(state, dispatch, filterIndex) {

  let newFilters = JSON.parse(JSON.stringify(state.filters))

  // Reset range values
  if (state.filters[filterIndex].type === "range") {
    newFilters[filterIndex].selectedValues = newFilters[filterIndex].values

  // Remove selected checkboxes
  } else if (state.filters[filterIndex].type === "checkbox") {
    newFilters[filterIndex].values.map(function (value) {
      value.isChecked = false
      return value
    })
  }

  // Remove active state from filter
  newFilters[filterIndex].active = false
  
  dispatch({
    type: "setFilters",
    data: newFilters
  })

}
