import React,{useContext, useState, useEffect} from 'react'
import { Context } from "../contexts/Store"
import { Link } from "react-router-dom"

function AptMarkerMP(props) {

const [divStyle, setDivStyle] = useState()
const [state, dispatch] = useContext(Context)
const [name , setName] = useState("")
const [thisYPos, setThisYPos] = useState(false)

useEffect(()=>{
  if(props.containerType === "Site"){
    let a = props.posY * 71.0
    setThisYPos(a + 14.5)
  }else{
    setThisYPos(props.posY * 100)
  }
},[])

useEffect(()=>{
  if(thisYPos){
    getName(state.plotContainers, props.id)
    
    const newDivStyle = {
      top: thisYPos + '%',
      left: props.posX * 100.0 + '%'
    }
    setDivStyle(newDivStyle)
  }
},[thisYPos])

function getName(obj, targetId){
  obj.every(function (plotContainerData){
    if(plotContainerData.id === targetId){
      setName(plotContainerData.name)
      return false
    }
    if (plotContainerData.hasOwnProperty('plotContainers')){
      getName(plotContainerData.plotContainers, targetId)
    }
    return true
  })   
}

return (
    <>
    {state.isPanning
      ? <div className="aptMarker"  style={divStyle}>{name}</div>
      : <Link className="aptMarker"  to={`${props.id}`} style={divStyle}>{name}</Link>
    }
    </>
  )
}

export default AptMarkerMP