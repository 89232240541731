import React,{ useContext, useEffect } from 'react';
import { Context } from "../contexts/Store"
import { useCookies } from "react-cookie"

let doOnce = false
let clearCacheTimeout

function ThinkSWController(props) {
  
  const [state, dispatch] = useContext(Context)
  const [cookies] = useCookies(["inMarketingSuite"])
 
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )

  const millisecondsUntil = () =>{
    //return milliseconds until 8am
    const now = new Date()
    let a = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0, 0) - now
    if (a < 0) 
      a += 86400000
      
      return a
  }

  const startSession = ()=> {
    doOnce = true
    preloadSpinAssets()
    
    if(cookies.inMarketingSuite == "true")
    clearCacheTimeout = setInterval( clearCacheEveryDay, millisecondsUntil())
  }

  const preloadSpinAssets = () => {
    //send message to service worker
    navigator.serviceWorker.controller.postMessage({
      type: 'ASSET_URL',
      globalConfigData :state.globalConfigData,
      devSpinPath: state.devSpinPath,
      devId: state.devId,
      isMarketingSuite: cookies.inMarketingSuite
    })
  }

  const clearCacheEveryDay = () => {
    //send message to service worker
    clearTimeout(clearCacheTimeout)
    clearCacheTimeout = setInterval( clearCacheEveryDay, millisecondsUntil())
    
    navigator.serviceWorker.controller.postMessage({
      type: 'CLEAR_CACHE'
    })
    preloadSpinAssets()
  }
  
  useEffect(()=>{
    let initServiceWorker = true

    if(isLocalhost) initServiceWorker = false //comment out for debug of sw on localhost
    if(state.isMobileDevice) initServiceWorker = false

    if(initServiceWorker){
      if('serviceWorker' in navigator){
        navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL + '/PreloadSW.js'}`)
        .then(reg => console.log('')
        )
        .catch(err => console.log(`Service Worker: Error: ${err}`))
      }
      //listen to message from service worker
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.msg === "READY" && !doOnce) startSession()
      })
    }
  },[])
  
  return null
}

export default ThinkSWController