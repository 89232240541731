import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../assets/styles/components/Photos.scss'
import photo1 from '../assets/img/photos-1.jpg'
import photo2 from '../assets/img/photos-2.jpg'
import photo3 from '../assets/img/photos-3.jpg'

function Photos(props) {

  const { ref, inView } = useInView({
    threshold: 0.25
  })

  return (
    <section ref={ref} className={`Photos animate ${inView ? 'active' : ''}`}>
      <div className="Photos__images">
        <img src={photo1} alt="" className="Photos__photo Photos__photo--1" />
        <img src={photo3} alt="" className="Photos__photo Photos__photo--2" />
        <img src={photo2} alt="" className="Photos__photo Photos__photo--3" />
      </div>
      <div className="Photos__content">
        <h2>Unrivalled craftsmanship and attention to detail</h2>
        <p>In all the homes at Wilton Park craftsmanship meets considered design with features such as oak handrails, polished chrome door furniture, pelmet lighting, underfloor heating, fitted wardrobes and electric car charging points.*</p>
        <p>And with carefully considered layouts that deliver a real sense of space, and a host of quality brand names; all of the homes have been designed to be as practical for everyday living as they are impressive when entertaining.</p>
      </div>
    </section>
  )
}

export default Photos
