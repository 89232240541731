import React, { useContext, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { Context } from "../contexts/Store"
import { priceFormatter, availableCount, findFloorplans, stringToSlug } from '../helpers/utilities'

import Meta from './Meta'
import AvailabilityBadge from './AvailabilityBadge'
import Button from './Button'
import ActionButton from './ActionButton'
import PlotLink from './PlotLink'
import FloorplanGalleryButton from "../components/FloorplanGalleryButton"
import InteriorTourButton from "../components/InteriorTourButton"


import '../assets/styles/components/Type.scss'
import { ReactComponent as IconHouse } from '../assets/img/house.svg'

/**
 * Type Component
 * @prop {array} item // ploteType props from Homes
 * @prop {array} state // use context
 * @prop {object} dispatch// use context
 * @state {string} isActiveType
 * @fun {function} handleClick
 */

function Type({ item }) {

  const [state, dispatch] = useContext(Context)

  let floorplans = findFloorplans(item.assets)
  const images = item.assets.filter((el) => el.name.indexOf('FP_') !== 0) // Filter out floorplans
  const image = (images.length) ? images[0] : floorplans[0]

  let interiorTour = null
  const typeSlug = stringToSlug(item.name)
  if (state.menuItems.interiorTours) {
    interiorTour = state.menuItems.interiorTours.find(
      (el) => el.slug === typeSlug
    )
  }

  const plotCount = availableCount(item.plots, state.plotStatuses)

  const isActiveType = (item === state.activeType)
  const [showPlots, setShowPlots] = useState(isActiveType)

  const handleClick = () => {
    setShowPlots(!showPlots)
    dispatch({
      type: 'setActiveType',
      data: item
    })
  }

  return (
    <section className="Type">
      <div className="Type__Inner">
        <div className="Type__Image">
          {image && 
            <img src={image.fileUrl} alt={image.name} loading="lazy" onClick={() => handleClick()} />
          }
        </div>
        <div className="Type__Main">
          <h2 className="Type__Name">{item.name}</h2>
          {availableCount(item.plots, state.plotStatuses) > 0 && 
            <p className="Type__Price">From {priceFormatter(item.fromPrice)}</p>
          }
        </div>
        <div className="Type__Meta">
          <Meta beds={item.numberOfBeds} area={item.imperialArea} />
        </div>
        <div className="Type__Actions">
          <FloorplanGalleryButton floorplans={floorplans} />
          <InteriorTourButton tour={interiorTour} />
          {/* <ActionButton icon="house-user">Explore</ActionButton> */}
        </div>
        <div className="Type__Controls">
          {plotCount > 0 && (
            <>
              <AvailabilityBadge count={plotCount} />
            </>
          )}
          <Button
            variant="secondary"
            className={showPlots ? 'active' : ''}
            size="xs"
            icon="chevron-down"
            onClick={() => handleClick()}
          >
            <IconHouse />
            View Homes
          </Button>
        </div>
      </div>
      {showPlots && (
        <AnimatePresence>
          <motion.div
            className="Type__Plots"
            initial={{ opacity: 0.5, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0.5, y: -50 }}
            transition={{ type: 'tween', delay: 0 }}
          >
            {state.filteredHomes.map((plot, i) => {
              if (plot.plotType.id === item.id) {
                return (
                  <PlotLink
                    plot={plot}
                    key={i}
                  />
                )
              }
            })}
          </motion.div>
        </AnimatePresence>
      )}
    </section>
  )
}

export default Type
