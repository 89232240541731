import React, { useContext, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Context } from "../contexts/Store"

import ToggleButton from './ToggleButton'

import '../assets/styles/components/Disclaimer.scss'

function Disclaimer() {

  const [state, dispatch] = useContext(Context)
  const [showDisclaimer, setShowDisclaimer] = useState(true)

  return (
    <AnimatePresence>
      {showDisclaimer ? (
          <motion.div
            className="Disclaimer"
            initial={{ y: 0, x: '-50%' }}
            animate={{ y: 20, x: '-50%' }}
            exit={{ opacity: 0 }}
          >
            <span className="Disclaimer__Text">{state.globalDisclaimer}</span>
            {state.isMobileDevice && (
              <ToggleButton icon="close" variant="secondary" size="sm" onClick={() => setShowDisclaimer(false)} />
            )}
          </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default Disclaimer
