import React from "react"
import { Link } from "react-router-dom"

import "../assets/styles/components/ActionButton.scss"
import { ReactComponent as IconFloorplan } from "../assets/img/floorplan.svg"
import { ReactComponent as IconHouseUser } from "../assets/img/house-user.svg"
import { ReactComponent as IconStreetView } from "../assets/img/streetview.svg"

function ActionButton({ variant = "primary", to, onClick, ...props }) {
  let icon
  switch (props.icon) {
    case "floorplan":
      icon = <IconFloorplan />
      break

    case "house-user":
      icon = <IconHouseUser />
      break

    case "streetview":
      icon = <IconStreetView />
      break
  }

  if (to) {
    return (
      <Link
        to={to}
        role="button"
        className={`ActionButton ActionButton--${variant}`}
        {...props}
      >
        {icon && <span className="ActionButton__Icon">{icon}</span>}
        <span className="ActionButton__Text">{props.children}</span>
      </Link>
    )
  } else {
    return (
      <div
        role="button"
        className={`ActionButton ActionButton--${variant}`}
        onClick={onClick}
        {...props}
      >
        {icon && <span className="ActionButton__Icon">{icon}</span>}
        <span className="ActionButton__Text">{props.children}</span>
      </div>
    )
  }
}

export default ActionButton
