import React,{ useContext, useState, useEffect } from 'react';
import { Context } from "../contexts/Store"
import { DropTarget } from 'react-drag-drop-container'

function VrMarker(props) {

  const [divStyle, setDivStyle] = useState();
  const [state, dispatch] = useContext(Context)
  const [markerSize, setMarkerSize] = useState(1)

  const { posX, posY, targId, scale } = props
  const { isVrpersonActive } = state

  useEffect(()=>{
    const a = 2.6 / scale
    setMarkerSize(a)
  },[scale])
  
  useEffect(()=>{
    const newDivStyle = {
      top: posY + '%',
      left: posX + '%',
      transform: "scale(" + markerSize + ")",
      zIndex: -1
    }
    setDivStyle(newDivStyle)

  },[posX, posY, markerSize])

  useEffect(()=>{
    if(isVrpersonActive){
      dragLeftElement()
    }else{
      const newDivStyle = {
        top: posY + '%',
        left: posX + '%',
        transform: "scale(" + markerSize + ")",
        transition: 'all 200ms ease-in-out',
        border: '1px solid rgba(255, 255, 255, 0)',
        zIndex: -1
      }
      setDivStyle(newDivStyle)
    }
  },[isVrpersonActive, markerSize])

function dragEntered(){
  const newMarkerSize = markerSize * 1.2
  const newDivStyle = {
    top: posY + '%',
    left: posX + '%',
    transform: "scale(" + newMarkerSize + ")",
    transition: 'all 200ms ease-in-out',
    border: '1px solid ' + state.devStyle.container.primary.color
  }
  setDivStyle(newDivStyle)
}

function dragLeftElement(){
  const newDivStyle = {
    top: posY + '%',
    left: posX + '%',
    transform: "scale(" + markerSize + ")",
    transition: 'all 200ms ease-in-out',
    border: '1px solid rgba(255, 255, 255, 1)',
    zIndex: 10
  }
  setDivStyle(newDivStyle)
}

return (
  <>
  {isVrpersonActive ?
  <DropTarget targetKey="vr_marker" 
  dropData={targId}
  onDragEnter={dragEntered}
  onDragLeave={dragLeftElement}
  >
    <div className="vrMarker" style={divStyle}/>
    </DropTarget>  : null }
  </>
    )
}

export default VrMarker