import React from 'react'

import '../assets/styles/components/Input.scss'

function Input({ name, id, placeholder, value, onChange, type = 'text', variant = 'primary', autoComplete = 'on', ...props }) {

  return (
    <input
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`Input Input--${variant}`}
      autoComplete={autoComplete}
    />
  )
}

export default Input
