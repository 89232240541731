import React, { useContext, useEffect, useState  } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { Context } from "../contexts/Store"

import Menu from './Menu'
import GlobalFilters from './GlobalFilters'
import Breadcrumbs from './Breadcrumbs'
import GlobalFavourites from './GlobalFavourites'
import SubMenu from './SubMenu'
import Disclaimer from './Disclaimer'

import '../assets/styles/components/Footer.scss'

function Footer() {

  const [state] = useContext(Context)
  const location = useLocation()
  const [displayFilter, setDisplayFilter] = useState(false)
  const logo = state.devStyle.images.additionalLogoUrl
  
  useEffect(() => {

    setDisplayFilter(false)

    if(state.filterRoutes && !location.pathname.includes('/homes/')){
      state.filterRoutes.every(function (thisRoute){
        const hasRoute = location.pathname.includes(thisRoute)
        if(hasRoute) {
          setDisplayFilter(true)
          return false
        }
        return true
      })
    }  
  }, [location, state.filterRoutes])

  return (
    <>
      {state.globalDisclaimer && (
        <Disclaimer />
      )}
      <div className="Footer">
        {logo && !state.isMobileDevice && 
          <Link to="/">
            <img src={logo} alt="" className="Footer__Logo" loading="lazy" />
          </Link>
        }

        <div className="Footer__Drawer">
          {displayFilter && (
            <GlobalFilters />
          )}
          <GlobalFavourites />
          <Menu />
        </div>
      </div>
    </>
  )
}

export default Footer
