import React from 'react'

import '../assets/styles/components/MapControl.scss'

import { ReactComponent as IconPlus } from '../assets/img/plus.svg'
import { ReactComponent as IconMinus } from '../assets/img/minus.svg'
import { ReactComponent as IconCenter } from '../assets/img/collapse.svg'
import { ReactComponent as IconLayers } from '../assets/img/layers.svg'

function MapControl({ variant = 'primary', label, onClick, className = '', disabled, ...props }) {
  
  let icon
  switch(variant) {
    case 'zoom-in':
      icon = <IconPlus />
      break

    case 'zoom-out':
      icon = <IconMinus />
      break

    case 'center-map':
      icon = <IconCenter />
      break

    case 'layers':
      icon = <IconLayers />
      break
  }

  return (
    <button
      id={props.id}
      className={`MapControl MapControl--${variant} ${className}`}
      onClick={onClick}
      title={label}
      disabled={disabled}
    >
      {icon}
      {!icon ? props.children : null}
    </button>
  )
}

export default MapControl
