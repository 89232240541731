import React,{useState, useEffect, useContext } from 'react'
import PlotMarker from "../components/PlotMarker"
import VrMarker from "../components/VrMarker"
import AptMarker from "../components/AptMarker"
import RoadMarker from "../components/RoadMarker"
import { Context } from "../contexts/Store"



function GetSpinDataFrame(props) {

  let plotMarkersTempArray = []
  let vrMarkersTempArray = []
  let aptMarkersTempArray = []
  let roadMarkersTempArray = []

  const [state, dispatch] = useContext(Context)
  const [plotMarkers, setPlotMarkers] = useState([])
  const [vrMarkers, setVrMarkers] = useState([])
  const [aptMarkers, setAptMarkers] = useState([])
  const [roadMarkers, setRoadMarkers] = useState([])
  const [thisSpinData, setThisSpinData] = useState(false)

  const { plots, filteredHomes, spinMarkerData, plotStatuses } = state
  const { spinAssetId, frameNumber, scale, tilt } = props
 
  let frameName
  let arrayCount 


  useEffect(() => {
    spinMarkerData.every(function (arrayItem){
      if(arrayItem.spinId == spinAssetId){
        setThisSpinData(arrayItem.spinData)
        return false
      }
      return true
    })

    if(thisSpinData){
      frameName = 'FRAME_' + frameNumber
      arrayCount = thisSpinData[frameName].length
      
      BuildMarkerArray()
      setPlotMarkers(plotMarkersTempArray)
      setVrMarkers(vrMarkersTempArray)
      setAptMarkers(aptMarkersTempArray)
      setRoadMarkers(roadMarkersTempArray)
    }
  
  }, [frameNumber, plots, filteredHomes, spinMarkerData, thisSpinData])

  
  function calculateMarkerPosition(thisX, thisY, thisZ) {
    
    let normalX = thisX / 4000.0
    let normalY = thisY / 4000.0
    
    let xd = normalX * 100
    let yd = (normalY * 100) + 0.3
    let zd = (thisZ / 100000)
    
    return {
      x: xd,
      y: yd,
      z: zd
    }
  }
  
  
  function BuildMarkerArray(){
    
    for (let i = 0; i< arrayCount; i++){
      
      for (let key in thisSpinData[frameName][i]) {
        
        let keyString = key
        
        if(keyString.includes("plot")){
          
          let n = keyString.indexOf("_")
          let rawPlotNumber = keyString.substring(n+1)
          //remove leading zeros
          let plotNumber = rawPlotNumber.replace(/^0+/, '')

          //let thisPlotInfo = plots.find(plotItem => plotItem.plotNumber == plotNumber)// keep for debug/ build [rich 24012022]
          let thisPlotInfo = filteredHomes.find(plotItem => plotItem.plotNumber == plotNumber)
          if(thisPlotInfo != null){

            let thiPlotStatus = plotStatuses.find(statusItem => statusItem.id == thisPlotInfo.plotStatusId)

            let markerDataPosX = parseInt(thisSpinData[frameName][i][key].x)
            let markerDataPosY = parseInt(thisSpinData[frameName][i][key].y)
            let markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist)

            let newMarkerPositions = calculateMarkerPosition(markerDataPosX, markerDataPosY, markerDataPosZ)

            plotMarkersTempArray.push({
              uid: i,
              x: newMarkerPositions.x,
              y: newMarkerPositions.y,
              z: newMarkerPositions.z,
              id: thisPlotInfo.id,
              colour: thiPlotStatus.color,
              num: plotNumber
            })
          }
        }else if(keyString.includes("vr")){
          
          let thisTarget = thisSpinData[frameName][i][key].target
          let markerDataPosX = parseInt(thisSpinData[frameName][i][key].x)
          let markerDataPosY = parseInt(thisSpinData[frameName][i][key].y)
          let markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist)

          let newMarkerPositions = calculateMarkerPosition(markerDataPosX, markerDataPosY, markerDataPosZ)
          let vrmarkerOffset = 1
          
          vrMarkersTempArray.push({
            uid: i,
            x: newMarkerPositions.x,
            y: newMarkerPositions.y - vrmarkerOffset,
            z: newMarkerPositions.z,
            id: thisTarget
          })
        
        }else if (keyString.includes("zn")){

          let thisTarget = thisSpinData[frameName][i][key].target
          let thisName = thisSpinData[frameName][i][key].name
          let markerDataPosX = parseInt(thisSpinData[frameName][i][key].x)
          let markerDataPosY = parseInt(thisSpinData[frameName][i][key].y)
          let markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist)

          let newMarkerPositions = calculateMarkerPosition(markerDataPosX, markerDataPosY, markerDataPosZ)
          
          aptMarkersTempArray.push({
            uid: i,
            x: newMarkerPositions.x,
            y: newMarkerPositions.y,
            z: newMarkerPositions.z,
            name: thisName,
            id: thisTarget
            })
          
          }else if (keyString.includes("road")){
            
            let thisName = thisSpinData[frameName][i][key].road
            let markerDataPosX = parseInt(thisSpinData[frameName][i][key].x)
            let markerDataPosY = parseInt(thisSpinData[frameName][i][key].y)
            let markerDataPosZ = parseInt(thisSpinData[frameName][i][key].dist)

            let newMarkerPositions = calculateMarkerPosition(markerDataPosX, markerDataPosY, markerDataPosZ)
            
            roadMarkersTempArray.push({
              uid: i,
              x: newMarkerPositions.x,
              y: newMarkerPositions.y,
              z: newMarkerPositions.z,
              name: thisName
            })
          }
        }
      }
    }
    
    return (
    <>
      {plotMarkers.map(thisPlotMarker => (
        <div key={thisPlotMarker.uid}><PlotMarker 
                                        posX={thisPlotMarker.x}
                                        posY={thisPlotMarker.y}
                                        posZ={thisPlotMarker.z}
                                        plotId={thisPlotMarker.id}
                                        plotNum={thisPlotMarker.num}
                                        colour={thisPlotMarker.colour}
                                        scale={scale}
                                        tiltAngle ={tilt}
                                        />
        </div>
      ))}
      {vrMarkers.map(thisVrMarker => (
        <div key={thisVrMarker.uid}><VrMarker
                                      posX={thisVrMarker.x}
                                      posY={thisVrMarker.y}
                                      targId={thisVrMarker.id}
                                      scale={scale}
                                      />
        </div>
      ))}
      {aptMarkers.map(thisAptMarker => (
        <div key={thisAptMarker.uid}><AptMarker
                                      posX={thisAptMarker.x}
                                      posY={thisAptMarker.y}
                                      name={thisAptMarker.name}
                                      id={thisAptMarker.id}
                                      scale={scale}
                                      />
        </div>
      ))}
      {roadMarkers.map(thisRoadMarker => (
        <div key={thisRoadMarker.uid}><RoadMarker
                                      posX={thisRoadMarker.x}
                                      posY={thisRoadMarker.y}
                                      posZ={thisRoadMarker.z}
                                      name={thisRoadMarker.name}
                                      />
        </div>
      ))}
    </>
  )
}

export default GetSpinDataFrame