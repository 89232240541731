import React,{useContext, useState, useEffect} from 'react'
import { Context } from "../contexts/Store"
import { Link } from "react-router-dom"

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

function AptMarker(props) {
  
  const [state, dispatch] = useContext(Context)
  const [divStyle, setDivStyle] = useState()
  const { posX, posY, name, id, scale } = props

  useEffect(()=>{
    let markerSize = 1.5 / scale
    markerSize = clamp(markerSize, 0.25, 1.0)
  
    const newDivStyle = {
      top: posY + '%',
      left: posX + '%',
      transform: "scale(" + markerSize + ")",
      zIndex: 2
    }
    setDivStyle(newDivStyle)

  }, [posX, posY, scale])

  return (
    <>
    {state.isPanning
      ? <div className="aptMarker" style={divStyle}>{name}</div>
      : <Link className="aptMarker" to={`${id}`} style={divStyle}>{name}</Link>
    }
    </>
    )
  }

  export default AptMarker