import React from 'react'

import '../assets/styles/components/DownloadIcon.scss'
import { ReactComponent as Icon } from '../assets/img/download-icon.svg'


function DownloadIcon({ className = '', ...props }) {
    return (
      <span className={`DownloadIcon ${className}`}>
        <Icon />
      </span>
    )
  }


export default DownloadIcon