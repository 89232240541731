import React from "react"

import { EqualHeightElement } from "react-equal-height"

function CompareRow({ name, label = true, ...props }) {
  return (
      <EqualHeightElement name={name}>
        <div className={`CompareItem CompareItem--${name}`}>
          {label &&
            <h3 className="CompareItem__Label">{name}</h3>
          }
          
          <div className="CompareItem__Item">
            {props.children}
          </div>
        </div>
      </EqualHeightElement>
  )
}

export default CompareRow
