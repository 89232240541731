import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../assets/styles/components/Panel.scss'
import image1 from '../assets/img/panel-1.jpg'
import image2 from '../assets/img/panel-2.jpg'

function Panel({ imageID = '1', ...props}) {

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  let image
  switch(imageID) {
    case '1':
      image = image1
      break
    case '2':
      image = image2
      break
  }

  return (
    <section ref={ref} className={`Panel animate ${inView ? 'active' : ''}`}>
      <div className="Panel__image">
        <img src={image} alt="" />
      </div>
      <div className="Panel__content">
        {props.children}
      </div>
    </section>
  )
}

export default Panel