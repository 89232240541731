import React, { useEffect, useState, useContext, useCallback } from "react"
import GoogleMapReact from "google-map-react"
import Axios from "axios"
import { Helmet } from "react-helmet"

import { Context } from "../contexts/Store"

import Loading from "../components/Loading"
import MapMark from "../components/MapMark"
import MapMarkDevelopment from "../components/MapMarkDevelopment"
import MapFilter from "../components/MapFilter"
import ToggleButton from "../components/ToggleButton"
import Overlay from '../components/Overlay'
import Panel from '../components/Panel'
import Cards from '../components/Cards'

import "../assets/styles/pages/Map.scss"

import SubMenu from "../components/SubMenu"

// const google = window.google;

function Map() {
  const [state, dispatch] = useContext(Context)

  let [key, setKey] = useState(0)
  const [localAreaData, setLocalAreaData] = useState()
  const [filteredPlaces, setFilteredPlaces] = useState([])
  const [showTransit, setShowTransit] = useState([])
  let devName = state.devName

  //get the location data from api
  useEffect(() => {
    if (!localStorage.getItem("localArea")) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `/v1/GetLocalArea/${state.devId}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": state.devKey }
            }
          )
          if (response.data) {
            //set state
            setLocalAreaData(response.data)
            setFilteredPlaces(response.data.poIs)
            setKey(key + 1) //update key to cause re-render of map

            //set localStorage
            let json = JSON.stringify(response.data)
            localStorage.setItem("localArea", json)
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    } else {
      //we have local data

      //set state
      const data = JSON.parse(localStorage.getItem("localArea"))
      setLocalAreaData(data)
      setFilteredPlaces(data.poIs)
    }
  }, [state.devId, state.devKey])

  //set up Google Maps

  const mapSettings = {
    center: {
      lat: 51.8860942,
      lng: 0.8336077
    },
    zoom: 5
  }

  function markerClicked(marker) {
    // console.log("The marker that was clicked is", marker)
  }

  function mapUpdated() {
    //to close the tooltip on map move
    document.body.click()
  }

  // Return map bounds based on list of places
  const getMapBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds()

    filteredPlaces.forEach((place) => {
      // if(place.categoryId == 1445){
      bounds.extend(new maps.LatLng(place.latitude, place.longitude))
      // }
    })
    return bounds
  }

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds)
      })
    })
  }

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps)

    // Fit map to bounds
    map.fitBounds(bounds)

    // Bind the resize listener
    bindResizeListener(map, maps, bounds)

    // fake scroll
    // so on iphone the tooltip follows pin
    window.scrollTo(window.scrollX, window.scrollY - 1)
    window.scrollTo(window.scrollX, window.scrollY + 1)
  }

  // set styles for grayscale elements on the map
  function createMapOptions(maps) {
    const grayscaleStylers = [
      { saturation: -100 },
      { gamma: 0.8 },
      { lightness: 4 }
    ]

    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      clickableIcons: false,
      fullscreenControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM
      },
      styles: [
        {
          featureType: "administrative",
          stylers: [...grayscaleStylers]
        },
        {
          featureType: "landscape",
          stylers: [...grayscaleStylers]
        },
        {
          featureType: "road",
          stylers: [...grayscaleStylers]
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: "poi",
          stylers: [...grayscaleStylers]
        },
        {
          featureType: "water",
          stylers: [...grayscaleStylers]
        }
      ]
    }
  }

  //filter markers on id
  function filterMarkers(id) {
    let filtered = []

    localAreaData.poIs.filter((el) => {
      if (
        id.includes(el.categoryId) ||
        el.categoryId === getDevelopmentCategoryID()
      ) {
        filtered.push(el)
      }
      return null
    })

    setFilteredPlaces(filtered)

    //TODO add fit to bounds on filter
  }

  //look up the category color from the category list
  function getCategoryColor(id) {
    let currentCategory = localAreaData.localAreaCategories.find(
      (el) => el.id === id
    )

    let { color } = currentCategory
    return color
  }

  function getCategoryName(id) {
    let currentCategory = localAreaData.localAreaCategories.find(
      (el) => el.id === id
    )

    let { name } = currentCategory
    return name
  }

  //get the "Development" category ID so we can display differently
  function getDevelopmentCategoryID() {
    let developmentCategory = localAreaData.localAreaCategories.find(
      (el) => el.name === "Development"
    )

    let { id } = developmentCategory
    return id
  }

  // Determine whether tube lines should be plotted on the map
  // Option is set in runtime-config
  useEffect(() => {
    if (state.showTransitOnMap) {
      setShowTransit(["TransitLayer"])
    }
  }, [state.showTransitOnMap])

  const toggleTransit = useCallback(() => {
    setShowTransit(!showTransit)
  }, [showTransit])

  return (
    <>
      <Helmet>
        <title>{`Local Area - ${state.devName.name}`}</title>
      </Helmet>

      <Panel imageID="1">
        <h2>Some of the best schools on your doorstep</h2>
        <p>A magnet for those wanting the very best educational opportunities for their children, Beaconsfield is renowned for the breadth of choice and calibre of schooling available. Importantly, Buckinghamshire is also one of the few counties that supports a selective grammar school system including Davenies, Wycombe Abbey, Godstowe and Caldicott.</p>
      </Panel>
      <Cards type="schools" />
      <Overlay imageID="3" align="center">
        <h2>Nestled in an area of outstanding natural beauty</h2>
        <p>With its landscape of beautiful rolling countryside, green hills, sparkling chalk streams and acres of ancient woodland, the Chiltern Hills are the perfect backdrop to relax and unwind. And with a wide choice of sedate walks, relaxing cycles or adventurous hikes, living at Wilton Park means you can enjoy both far-reaching views and natural beauty up-close – all near to home.</p>
      </Overlay>

      <div
        className="map-wrap"
      >
        <SubMenu>
          <div className="SubMenu__Item">
            <ToggleButton
              icon="tfl"
              onClick={() => setShowTransit(!showTransit)}
              size="sm"
              active={showTransit}
            >
              Transit
            </ToggleButton>
          </div>
          <div className="SubMenu__Item">
            <MapFilter
              localAreaData={localAreaData}
              filterMarkers={filterMarkers}
              getDevelopmentCategoryID={getDevelopmentCategoryID}
            />
          </div>
        </SubMenu>

        <h1 style={{ margin: '50px 0 20px', textAlign: 'center', fontWeight: 'bold' }}>Explore the area</h1>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB8XKQG2wreOLoUjyXhlmPdwKyAFsFaNUc" }}
          defaultCenter={mapSettings.center}
          defaultZoom={mapSettings.zoom}
          onChildClick={markerClicked}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          options={createMapOptions}
          key={key}
          layerTypes={showTransit ? ["TransitLayer"] : []}
        >
          {filteredPlaces ? (
            filteredPlaces.map((place) => {
              if (getDevelopmentCategoryID() !== place.categoryId) {
                getCategoryName(place.categoryId)
                return (
                  <MapMark
                    key={place.id}
                    lat={place.latitude}
                    lng={place.longitude}
                    color={getCategoryColor(place.categoryId)}
                    name={place.name}
                    categoryName={getCategoryName(place.categoryId)}
                  />
                )
              }
            })
          ) : (
            <Loading />
          )}

          {filteredPlaces
            ? filteredPlaces.map((place) => {
                if (getDevelopmentCategoryID() == place.categoryId) {
                  return (
                    <MapMarkDevelopment
                      key={place.id}
                      lat={place.latitude}
                      lng={place.longitude}
                      name={place.name}
                    />
                  )
                }
              })
            : null}
        </GoogleMapReact>
      </div>
      <Panel imageID="2">
        <h2>Connected to the city and beyond</h2>
        <p>Despite its semi-rural surroundings, Beaconsfield is well connected. Wilton Park is less than 1.5 miles from Beaconsfield station which offers frequent, direct services via Chiltern Railways to London Marylebone in as little as 23 minutes. From here, there’s direct access to the London Underground network. Road connections are just as good; junction 2 of the M40 is less than a mile away, and the M40/M25 interchange within five miles.</p>
      </Panel>
      <Cards type="locations" />
    </>
  )
}

export default Map
