import React from 'react'

import '../assets/styles/components/LocationCard.scss'
import { ReactComponent as IconSchool } from '../assets/img/icon-school.svg'
import { ReactComponent as IconCar } from '../assets/img/icon-car.svg'

function LocationCard({ title, type = 'school', meta, distance }) {

  let icon
  switch (icon) {
    case 'school':
    default:
      icon = <IconSchool />
      break
    
    case 'car':
      icon = <IconCar />
      break
  }

  return (
    <div className="LocationCard">
      <h4 className="LocationCard__title">
        {title}
        {icon}
      </h4>
      <p className="LocationCard__meta">{meta}</p>
      <p className="LocationCard__distance">{distance}</p>
    </div>
  )
}

export default LocationCard
