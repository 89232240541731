import React, { useContext, useState } from "react"
import { getStatus } from "../helpers/utilities"
import { Context } from "../contexts/Store"
import "../assets/styles/components/AvailabilityBadge.scss"

import { ReactComponent as IconTick } from "../assets/img/tick.svg"
import { ReactComponent as IconCross } from "../assets/img/cross.svg"
import { ReactComponent as IconHourGlass } from "../assets/img/hourglass.svg"

const Icon = ({ status }) => {
  if (status.name === "Available") {
    return <IconTick />
  } else if (status.name === "Sold" || status.name === "Reserved") {
    return <IconCross />
  } else {
    return <IconHourGlass />
  }
}

function AvailabilityBadge({ variant = "primary", status, count }) {
  const [state, dispatch] = useContext(Context)

  if (!status && count > 0) {
    let availableStatus = getStatus(state.plotStatuses, "name", "Available")
    status = availableStatus
  }

  return (
    <div className={`AvailabilityBadge AvailabilityBadge--${variant}`}>
      <div style={{ color: `${status.color}` }}>
        <Icon status={status} />
      </div>
      {}
      {status.name === "Available" && count
        ? `${count} Available`
        : status.name}
    </div>
  )
}

export default AvailabilityBadge
