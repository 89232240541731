import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../contexts/Store'
import { useInView } from 'react-intersection-observer'

import Button from '../components/Button'

import '../assets/styles/components/PageHeader.scss'
import headerimage from '../assets/img/header-image.jpg'

function PageHeader() {

  const [state, dispatch] = useContext(Context)
  const { ref, inView } = useInView()

  return (
    <header ref={ref} className={`PageHeader animate ${inView ? 'active' : ''}`}>
      <div className="PageHeader__logo">
        <img src={state.devStyle.images.wallLogoUrl} alt="" />
      </div>
      <div className="PageHeader__row">
        <div className="PageHeader__content">
          <h1>Welcome to Wilton Park</h1>
          <p>Much more than a collection of quality new homes from Bewley, Wilton Park offers new parkland, amenities, enhanced biodiversity and a thriving and sustainable community in a historic location close to the charming market town of Beaconsfield and just 23 minutes from London Marylebone.</p>
          <Button variant="secondary" to="/homes">See our homes</Button>
        </div>
        <div className="PageHeader__image">
          <img src={headerimage} />
          <video autoplay="true" looped="true" muted="true" controls="true" poster={headerimage}>
            <source src="https://player.vimeo.com/progressive_redirect/playback/625354099/rendition/720p?loc=external&signature=901b56674bbe08830bb04bdc3aedb02c612e83c2d6e20a710ce54d6f185342d8" type="video/mp4"></source>
          </video>
        </div>
      </div>
    </header>
  )
}

export default PageHeader
