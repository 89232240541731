import React, { useContext, useRef, useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

import { Context } from "../contexts/Store"

import Filter from "./Filter"
import ToggleButton from "./ToggleButton"

import "../assets/styles/components/GlobalFilters.scss"
import { ReactComponent as IconTick } from "../assets/img/tick.svg"
import { ReactComponent as IconChevronDown } from "../assets/img/chevron-down.svg"

function GlobalFilters() {
  const [state, dispatch] = useContext(Context)
  const [activeFilter, setActiveFilter] = useState(0)

  const countActiveFilters = () => {
    return state.filters.filter((item) => item.active).length
  }

  /*
   * Handle click outside
   */
  const ref = useRef(null)
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      dispatch({
        type: "setFiltersOpen",
        data: false
      })
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  let animateStyle = { originX: 0.95, originY: 0.95 }

  if (state.isMobileDevice) {
    animateStyle = { originX: 0, originY: 1 }
  }

  return (
    <div className="GlobalFilters" ref={ref}>
      <AnimatePresence>
        {state.isFiltersOpen && (
          <motion.div
            className="GlobalFilters__Filters"
            style={animateStyle}
            initial={{ opacity: 0, y: 10, scale: 0.5 }}
            animate={{ opacity: 1, y: -5, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.5 }}
            transition={{
              duration: 0.25,
              ease: [0.6, 0.01, -0.05, 0.95]
            }}
          >
            {state.filters.map((item, i) => {
              let buttonClassName =
                activeFilter === i ? "GlobalFilters__Toggle--active " : ""
              buttonClassName +=
                item.name === "plotStatus" ? "GlobalFilters__Toggle--fade " : ""

              return (
                <div className="GlobalFilters__Filter" key={i}>
                  <button
                    className={`GlobalFilters__Toggle ${buttonClassName}`}
                    onClick={() => setActiveFilter(i)}
                  >
                    {item.active && (
                      <IconTick className="GlobalFilters__Toggle__Tick" />
                    )}
                    {item.displayName}
                    <IconChevronDown className="GlobalFilters__Toggle__Chevron" />
                  </button>
                  {activeFilter === i && (
                    <div className="GlobalFilters__Filter__Items">
                      <div className="GlobalFilters__Filter__Items__Inner">
                        <Filter filter={item} index={i} key={i} />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </motion.div>
        )}
      </AnimatePresence>
      <ToggleButton
        icon="filters"
        onClick={() =>
          dispatch({ type: "setFiltersOpen", data: !state.isFiltersOpen })
        }
        count={countActiveFilters()}
      />
    </div>
  )
}

export default GlobalFilters
