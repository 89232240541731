import React, { useContext, useState } from 'react'

import { Context } from "../contexts/Store"

import Filter from './Filter'
import Button from './Button'

import '../assets/styles/components/HomeFilters.scss'
import { ReactComponent as IconFilters } from '../assets/img/filter.svg'
import { ReactComponent as IconTick } from '../assets/img/tick.svg'

function HomeFilters() {

  const [state, dispatch] = useContext(Context)

  const [activeFilter, setActiveFilter] = useState(state.filters[0].displayName)

  return (
    <div className="HomeFilters">
      <div className="HomeFilters__Container">
        <h2 className="HomeFilters__Title">
          <IconFilters />
          What are you looking for?
        </h2>
        <div className="HomeFilters__Inner">
          {state.filters.map((item, i) => {
            if (item.displayName === 'Status') return

            if (activeFilter === item.displayName) {
              return (
                <Filter filter={item} index={i} key={i} />
              )
            }
          })}
        </div>
        <div className="HomeFilters__Tabs">
          {state.filters.map((item, i) => {
            if (item.displayName === 'Status') return

            return (
              <Button
                onClick={() => setActiveFilter(item.displayName)}
                key={i}
                size="sm"
                variant={activeFilter === item.displayName ? 'secondary' : 'light'}
              >
                {item.active && <IconTick />}
                {item.displayName}
              </Button>
            )
          })}
        </div>
      </div>
      <div className="HomeFilters__Footer">
        {state.filteredHomes.length >= 1 ?
          <Button
            to="homes"
            variant="secondary"
            icon="arrow"
          >
            View <b>{state.filteredHomes.length}</b> Homes
          </Button>
        : 
          <div className="HomeFilters__Message">
            No results found.
          </div>
        }
      </div>
    </div>
  )
}

export default HomeFilters
