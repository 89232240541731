import React,{useContext, useState, useEffect } from 'react'

import { Context } from "../contexts/Store"

/**
 * PlotMarker component
 * @props {number} posX
 * @props {number} posY
 * @props {number} posZ
 * @props {string} colour
 * @props {number} plotId
 * @props {string} plotNum
 * @state {boolean} isMobileDevice
 * @state {boolean} isPanning
 * @state {boolean} selectedPlotId
 * @state {boolean} isPreviewPanelOpen
 * @state {object} divStyle
 * @state {object} divStyleMobile
 * @fun {function} PlotMarkerClicked
 */

function PlotMarker(props) {
  const [state, dispatch] = useContext(Context)
  const { isMobileDevice, isPanning, selectedPlotId, isPreviewPanelOpen } = state
  const { posX, posY, posZ, plotId, plotNum, colour, scale, tiltAngle } = props
  const [divStyle, setDivStyle] = useState()
  
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

  useEffect(() => {
    let markerSize = 1.5 / scale
    markerSize = clamp(markerSize, 0.25, 1.0)
    
    let markerOffsetHeight = 1.0
    if(isMobileDevice)
    markerOffsetHeight = 3.0
    
    const modifier = markerOffsetHeight/60
    const b = -(30 -tiltAngle) * modifier
    const newPosY = posY - ((markerOffsetHeight - b) * markerSize)
      
    let markerRadius = "50% 50% 50% 0"
    if(tiltAngle > 70 )
    markerRadius = "100%"

    const newDivStyle = {
      top: newPosY + "%",
      left: posX + "%",
      zIndex: posZ,
      transform: "rotate(-45deg) scale(" + markerSize + ")",
      backgroundColor: colour,
      borderRadius: markerRadius
    }
    setDivStyle(newDivStyle)

  }, [posX, posY, colour, posZ, scale, tiltAngle])

  function PlotMarkerClicked() {
    if (!isPanning) {
      dispatch({
        type: "showPlotPreview",
        data: {
          plotid: plotId,
          markerId: plotNum
        }
      })
    }
  }

  const getSelectedPlotId = selectedPlotId === plotId && isPreviewPanelOpen ? "plotMarker--active" : ""

  return (
    <>
      <a
        key={plotNum}
        id={plotNum}
        className={ `plotMarker  ${getSelectedPlotId}` }
        onClick={PlotMarkerClicked}
        style={divStyle} 
      >
        <span>{plotNum}</span>
      </a>
    </>
  )
}

export default PlotMarker