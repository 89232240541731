import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Context } from "../contexts/Store"
import { use100vh } from "react-div-100vh"

function Theme() {

  const [state, dispatch] = useContext(Context)

  function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    let color = result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null

    return color ? `${color.r}, ${color.g}, ${color.b}` : null
  }

  const viewportHeight = use100vh()

  const styles = `
    :root {
      --theme-background-color: ${state.devStyle.container.toolbarPrimary};
      --theme-background-image: url(${state.devStyle.images.secondaryBackgroundImageUrl});
      --theme-primary-color: ${state.devStyle.container.primary.color};
      --theme-secondary-color: ${state.devStyle.container.secondary};
      --theme-text-color: ${state.devStyle.container.text};
      --theme-card-background-color: ${state.devStyle.container.toolbarSecondary};
      --theme-card-background-tint-color: ${state.devStyle.container.stroke.color};
      --theme-border-radius: ${state.devStyle.container.stroke.width}px;

      --menu-primary-color: ${state.devStyle.menu.primary.color};
      --menu-secondary-color: ${state.devStyle.menu.secondary};
      --menu-text-color: ${state.devStyle.menu.text};
      --menu-card-text-color: ${state.devStyle.menu.selectedText};
      --menu-card-background-color: ${state.devStyle.menu.stroke.color};
      --menu-border-radius: ${state.devStyle.menu.stroke.width}px;

      --backdrop-color-rgb: ${hexToRgb(state.devStyle.container.toolbarPrimary)};
      --backdrop-color-secondary-rgb: ${hexToRgb(state.devStyle.container.secondary)};

      --100vh: ${viewportHeight}px;
    }
  `

  return (
    <Helmet>
      <meta name="theme-color" content={state.devStyle.container.toolbarPrimary} />
      <style>{styles}</style>
    </Helmet>
  )
}

export default Theme
