import React, { useState, useContext, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useCookies } from "react-cookie"
import { EqualHeight, EqualHeightElement } from "react-equal-height"

import { Context } from "../contexts/Store"

import PlotCard from "../components/PlotCard"
import PlotCompareCard from "../components/PlotCompareCard"
import Alert from "../components/Alert"
import Button from "../components/Button"
import SubMenu, { SubMenuViewTabs } from "../components/SubMenu"
import LoginForm from "../components/LoginForm"
import FavouritesQRCode from "../components/FavouritesQRCode"

import "../assets/styles/pages/Favourites.scss"
import { ReactComponent as IconTrash } from '../assets/img/trash.svg'

function Favourites() {
  const [state, dispatch] = useContext(Context)

  const [cookies] = useCookies(["inMarketingSuite"])
  const [inMarketingSuite, setInMarketingSuite] = useState(
    cookies.inMarketingSuite === "true" ? true : false
  )

  const [activeView, setActiveView] = useState("list")
  const views = [
    {
      label: "List",
      slug: "list"
    },
    {
      label: "Compare",
      slug: "compare"
    }
  ]

  const clearAllFavourites = () => {
    dispatch({
      type: "clearFavPlots"
    })
  }

  return (
    <div className="Homes">
      <Helmet>
        <title>{`Favourites - ${state.devName.name}`}</title>
      </Helmet>

      <SubMenu>
        <SubMenuViewTabs
          views={views}
          activeView={activeView}
          setActiveView={setActiveView}
        ></SubMenuViewTabs>
      </SubMenu>

      {state.favPlots.length ? (
        <>
          {activeView === "list" && (
            <div className="container">
              <div className="Plot__Grid">
                {state.favPlots.map((id, i) => {
                  const item = state.plots.find((plot) => id === plot.id)
                  if (item) {
                    return <PlotCard item={item} key={i} />
                  }
                  return null
                })}
              </div>
            </div>
          )}

          {activeView === "compare" && (
            <div className={`Compare__Grid ${state.favPlots.length < 4 ? 'Compare__Grid--centered' : ''}`}>
              <EqualHeight>
                {state.favPlots.map((id, i) => {
                  const item = state.plots.find((plot) => id === plot.id)
                  if (item) {
                    return <PlotCompareCard item={item} key={i} />
                  }
                  return null
                })}
              </EqualHeight>
            </div>
          )}
        </>
      ) : (
        <Alert>
          No favourites found. Visit the <Link to="/homes">Homes</Link> page to
          add some.
        </Alert>
      )}

      <section className="Favourites__Footer">
        {state.showLogin && (
          <>
            {!inMarketingSuite ?
              <div className="Favourites__Login">
                  <LoginForm />
              </div>
            : state.favPlots.length > 0 ?
              <div className="Favourites__Sync">
                <FavouritesQRCode />
              </div>
            :
              null
            }
          </>
        )}
        <div className="Favourites__Clear">
          {state.favPlots.length > 0 && (
            <Button onClick={clearAllFavourites} variant="light" size="sm" className="Button--trash">
              <IconTrash />
              Clear all favourites
            </Button>
          )}
        </div>
      </section>
    </div>
  )
}

export default Favourites
