import { useRef, useEffect } from 'react'

export function priceFormatter(price) {
  
  let retVal = "£POA"

  if(parseInt(price) >0){
    let priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    retVal = priceFormatter.format(price).replace(/\D00$/, "")
  }
  
  return retVal
}

export function availableCount(plots, plotStatuses) {
  let availableStatus = getStatusID(plotStatuses, "Available")
  let count = 0
  if (Array.isArray(plots) && plots.length > 0) {
    plots.map((i) => {
      if (i.plotStatusId === availableStatus) {
        count++
      }
      return null
    })
  }
  return count
}

export function getPlotById(plots, Id) {
  return plots.find((plot) => plot.id === Id)
}

// Count the number of plots with this type in the filteredHomes object in state
export function filteredCountByType(plots, type) {
  let count = 0
  if (Array.isArray(plots) && plots.length > 0) {
    plots.map((i) => {
      if (i.plotTypeId === type.id) {
        count++
      }
      return null
    })
  }
  return count
}

export function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

// get from price from array
export function fromPrice(array) {
  return Math.min.apply(
    Math,
    array
      .filter((i) => {
        if (i > 0) {
          return true
        }
        return false // skip
      })
      .map((i) => {
        return i
      })
  )
}

//get to price from array
export function toPrice(array) {
  return Math.max.apply(
    Math,
    array
      .filter((i) => {
        if (i > 0) {
          return true
        }
        return false // skip
      })
      .map((i) => {
        return i
      })
  )
}

//Status ID
export function getStatusID(array, status) {
  let result = array.filter((el) => {
    if (el.name === status) {
      return el
    }
    return null
  })[0]
  return result.id
}

export function getStatus(statusesArray, searchType, term) {
  let result = null
  if (searchType === "id") {
    result = statusesArray.filter((el) => {
      if (el.id === term) {
        return el
      }
      return null
    })[0]
  } else if (searchType === "name") {
    result = statusesArray.filter((el) => {
      if (el.name === term) {
        return el
      }
      return null
    })[0]
  }
  return result
}

// Custom hook to get previous useRef value - https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}


export function findFloorplans(images) {
  let floorplans = []
  images.map((item) => {
    if (item.name.startsWith("FP")) {
      let floorplan, caption

      //add caption
      if (item.name.endsWith("0")) {
        caption = "Ground Floor"
      } else if (item.name.endsWith("1")) {
        caption = "First Floor"
      } else if (item.name.endsWith("2")) {
        caption = "Second Floor"
      } else if (item.name.endsWith("3")) {
        caption = "Third Floor"
      } else if (item.name.endsWith("4")) {
        caption = "Fourth Floor"
      } else {
        caption = ""
      }

      floorplan = { ...item, caption: caption }

      floorplans.push(floorplan)
    }
    return null
  })
  return floorplans
}
