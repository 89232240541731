import React from 'react'
import { Link } from 'react-router-dom'

import ArrowIcon from './ArrowIcon'

import '../assets/styles/components/IconButton.scss'
import { ReactComponent as IconStreetView } from '../assets/img/street-view.svg'
import { ReactComponent as IconSearch } from '../assets/img/search-location.svg'
import { ReactComponent as IconFilters } from '../assets/img/filter.svg'

function IconButton({ onClick, to, ...props }) {
  
  let icon
  switch(props.icon) {
    case 'street-view':
      icon = <IconStreetView />
      break
    
    case 'search':
      icon = <IconSearch />
      break
    
    case 'filters':
      icon = <IconFilters />
      break
  }
  
  if (to) {
    return (
      <Link
        to={to}
        className="IconButton"
        onClick={onClick}
      >
        {icon && 
          <span className="IconButton__Icon">{icon}</span>
        }
        <span className="IconButton__Text">{props.children}</span>
        <ArrowIcon className="IconButton__Arrow" />
      </Link>
    )
  } else {
    return (
      <button
        className="IconButton"
        onClick={onClick}
      >
        {icon && 
          <span className="IconButton__Icon">{icon}</span>
        }
        <span className="IconButton__Text">{props.children}</span>
        <ArrowIcon className="IconButton__Arrow" />
      </button>
    )
  }
}
  
export default IconButton
  