import React from 'react'
import { useInView } from 'react-intersection-observer'

import LocationCard from './LocationCard'

import '../assets/styles/components/Cards.scss'
import '../assets/styles/components/Card.scss'
import image1 from '../assets/img/card-1.jpg'
import image2 from '../assets/img/card-2.jpg'
import image3 from '../assets/img/card-3.jpg'

function Cards({ title, type = 'cards', ...props }) {

  const { ref, inView } = useInView({
    threshold: 0.25
  })

  const cards = [
    {
      'image': image1,
      'title': 'Green Open Spaces',
      'content': '<p>A rich mix of green spaces include two hectares of parkland, wildflower meadows, the retention of a variety of mature trees and an additional 140 new trees planted.</p>'
    },
    {
      'image': image2,
      'title': 'Proposed Café',
      'content': '<p>A focal point for the whole Wilton Park community. The café will be a place to relax, chat over a coffee or enjoy a bite to eat with friends.</p>'
    },
    {
      'image': image3,
      'title': 'A Connected Landscape',
      'content': '<p>Pedestrian pathways and cycleways connect home to informal recreation space and beyond. This playful landscape is perfect for walking, cycling, resting, exercising and ball sports.</p>'
    },
    {
      'image': image1,
      'title': 'Green Open Spaces',
      'content': '<p>A rich mix of green spaces include two hectares of parkland, wildflower meadows, the retention of a variety of mature trees and an additional 140 new trees planted.</p>'
    },
    {
      'image': image2,
      'title': 'Proposed Café',
      'content': '<p>A focal point for the whole Wilton Park community. The café will be a place to relax, chat over a coffee or enjoy a bite to eat with friends.</p>'
    },
    {
      'image': image3,
      'title': 'A Connected Landscape',
      'content': '<p>Pedestrian pathways and cycleways connect home to informal recreation space and beyond. This playful landscape is perfect for walking, cycling, resting, exercising and ball sports.</p>'
    }
  ]

  const schools = [
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    },
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    },
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    },
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    },
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    },
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    },
    {
      'title': 'Davenies School',
      'type': 'school',
      'meta': 'Preparatory',
      'distance': '0.8 miles'
    }
  ]

  const locations = [
    {
      'title': 'Heathrow Airport',
      'type': 'car',
      'distance': '5 minutes'
    },
    {
      'title': 'High Wycombe',
      'type': 'car',
      'distance': '15 minutes'
    },
    {
      'title': 'Heathrow Airport',
      'type': 'car',
      'distance': '5 minutes'
    },
    {
      'title': 'High Wycombe',
      'type': 'car',
      'distance': '15 minutes'
    },
    {
      'title': 'Heathrow Airport',
      'type': 'car',
      'distance': '5 minutes'
    },
    {
      'title': 'High Wycombe',
      'type': 'car',
      'distance': '15 minutes'
    }
  ]

  return (
    <section ref={ref} className={`Cards animate ${inView ? 'active' : ''}`}>
      <h2>{title}</h2>
      <div className="Cards__row">
        {type === 'cards' && cards.map((item, key) => {
          return (
            <div className="Card" key={key}>
              <div className="Card__image">
                <img src={item.image} alt="" />
              </div>
              <div className="Card__content">
                <h3>{item.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            </div>
          )
        })}
        {type === 'schools' && schools.map((item, key) => {
          return (
            <LocationCard key={key} title={item.title} type={item.type} meta={item.meta} distance={item.distance} />
          )
        })}
        {type === 'locations' && locations.map((item, key) => {
          return (
            <LocationCard key={key} title={item.title} type={item.type} meta={item.meta} distance={item.distance} />
          )
        })}
      </div>
    </section>
  )
}

export default Cards
