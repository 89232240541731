import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../assets/styles/components/Gallery.scss'
import image1 from '../assets/img/gallery-image.jpg'
import image2 from '../assets/img/gallery-image-2.jpg'

function Gallery({ title = 'Discover the Bewley Difference' }) {

  const { ref, inView } = useInView({
    threshold: 0.25
  })

  return (
    <section ref={ref} className={`GalleryBlock animate ${inView ? 'active' : ''}`}>
      <div className="GalleryBlock__row GalleryBlock__row--1">
        <div className="GalleryBlock__image GalleryBlock__image--1">
          <img src={image1} alt="" />
        </div>
        <div className="GalleryBlock__content">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="GalleryBlock__row GalleryBlock__row--2 GalleryBlock__row--reverse">
        <div className="GalleryBlock__content">
          <p>With a real sense of arrival, nuanced architectural features and attention to detail, the Bewley difference can be experienced in all of the homes at Wilton Park. Here, the character and proportions of a classical design meet a specification designed for a contemporary lifestyle.</p>
          <p>From the light-filled entrance halls, the high ceilings, stunning designer kitchens with integrated appliances, and sleek bathroom suites, you’ll know your new Bewley home is truly special.</p>
        </div>
        <div className="GalleryBlock__image GalleryBlock__image--2">
          <img src={image2} alt="" />
        </div>
      </div>
    </section>
  )
}

export default Gallery
