import React from "react"
import Fancybox from "../components/Fancybox"

import { ReactComponent as ZoomIcon } from '../assets/img/zoom-icon.svg'
import '../assets/styles/components/ToggleButton.scss'


function ImageZoomButton({ src, caption, gallery, ...props }) {
  return (
    <Fancybox
    options={{
      Toolbar: false,
      closeButton: "outside",
    }}
  >
    <button
    className="ToggleButton"
      data-fancybox={gallery}
      data-caption={caption}
      data-src={src}
    >
      <span className="ToggleButton__Icon"><ZoomIcon /></span>
    </button>
  </Fancybox>
  )
}

export default ImageZoomButton
