import React from "react"
//import Spinner from "react-bootstrap/Spinner"

function LoadingDotsIcon() {
  return (
    ''
  )
}

export default LoadingDotsIcon
