import React, { useEffect, useContext } from "react"
import { CookiesProvider, useCookies } from "react-cookie"
import { BrowserRouter } from "react-router-dom"
import { Helmet } from "react-helmet"

import { get, post, getNoHeaders, xhrDelete } from './adapters/xhr'
import { Context } from "./contexts/Store"
import { setupFilters, runFilters } from "./contexts/Filters"
import ThinkSWController from './components/ThinkSWController'

//import * as serviceWorkerRegistration from "./helpers/serviceWorkerRegistration"
import getThinkHubData from "./helpers/useThinkHubData"
import plotsToTypes from "./helpers/plotsToTypes"
import parseMenuItems from "./helpers/parseMenuItems"

import AppRoutes from "./pages/AppRoutes"

import Theme from "./components/Theme"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Spinner from "./components/LoadingSpinner"
import IdleTimerContainer from "./components/IdleTimerContainer"
import LightServer from "./components/LightServer"
import CheckDevice from "./components/CheckDevice"

import "./assets/styles/style.scss"
import LogRocket from "logrocket"

const App = () => {
  const [state, dispatch] = useContext(Context)

  const [cookies] = useCookies([
    "inMarketingSuite",
    "haveLights",
    "lightServerAddress"
  ])

  //check for webp support
  async function supportsWebp() {
    if (!self.createImageBitmap) return false
    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
    const blob = await fetch(webpData).then(r => r.blob())
    return createImageBitmap(blob).then(() => true, () => false)
  }

  useEffect(()=>{
    (async () => {
      if(await supportsWebp())
      dispatch({
        type: "setSupportsWebp"
      })
    })()
  },[])

  getThinkHubData()

  //get plot VR data
  useEffect(() => {
    if (state.allLoaded && state.menuItems && state.menuItems.plotVRs) {
      getNoHeaders(state.menuItems.plotVRs).then((response) => {
        if (response.data) {
          dispatch({
            type: "SET_PLOT_VRS",
            data: response.data
          })
        }
      })
    }
  }, [state.appLoaded, state.menuItems])

  //get siteplan config data
  useEffect(() => {

    let devSpinPath = window['runConfig'].devSpinPath
    
    if(devSpinPath != ""){
      let devId = window['runConfig'].devId
      
      getNoHeaders(`${devSpinPath}${devId}/config.json`).then((response) => {
        if (response.data) {
          dispatch({
            type: "setGlobalConfigData",
            data: response.data
          })
        }
      })
    }
  }, [])

//get siteplan spin data set(s)
  useEffect(()=>{
    
    let devSpinPath = window['runConfig'].devSpinPath
    let devId = window['runConfig'].devId
    
    if(state.globalConfigData){
      if(state.globalConfigData.spins[0] !="" ){

      state.globalConfigData.spins.forEach(thisSpinId => {
        
        getNoHeaders(`${devSpinPath}${devId}/${thisSpinId}/spinExport.json`).then((response) => {
          if (response.data) {
            const tempObj = {"spinId": thisSpinId, "spinData": response.data}
            dispatch({
              type: "setSpinMarkerData",
              data: tempObj
            })
          }
        })
        getNoHeaders(`${devSpinPath}${devId}/${thisSpinId}/spinConfig.json`).then((response) => {
          if (response.data) {
            const tempObj = {"spinId": thisSpinId, "spinConfigData": response.data}
            dispatch({
              type: "setSpinConfigData",
              data: tempObj
            })
          }
        })




      })
    }
  }

  },[state.globalConfigData])

  //parseMenuItems
  useEffect(() => {
    if (state.allLoaded) {
      let menuItemsRaw = parseMenuItems(state.menuItemsRaw)
      dispatch({ type: `SET_MENU_DATA`, payload: menuItemsRaw })
    }
  }, [state.allLoaded])

  //Transform plots to types
  useEffect(() => {
    if (state.allLoaded) {
      let types = plotsToTypes(state.plots, state.plotStatuses)
      dispatch({ type: `SET_TYPES_DATA`, payload: types })
    }
  }, [state.allLoaded])

  //Set up the filters
  useEffect(() => {
    if (state.allLoaded) {
      setupFilters(state, dispatch)
    }
  }, [state.allLoaded])


  //run filters
  useEffect(() => {
    if (state.availableHomes) {
      runFilters(state, dispatch)
    }
  }, [state.availableHomes, state.filters])

  //load favs
  useEffect(() => {
    localStorage.setItem("favPlots", JSON.stringify(state.favPlots))
  }, [state.favPlots])

  //update remote fav plots
  useEffect(() => {
    async function addRemoteFavPlots() {
      try {
        await post(`/v2/AddPlotsToCustomerFavourites/`, {
          customerEmail: state.user.email,
          PlotIds: state.favPlotsToAdd
        })

        dispatch({ type: "remotePlotAdded" })

      } catch (error) {
        console.log(error.response)
      }
    }

    if (state.loggedIn && state.favPlotsToAdd.length > 0) {
      addRemoteFavPlots()
    }
  }, [state.favRemoteUpdate])

  //remove fav plot from remote
  useEffect(() => {
    async function removeRemoteFavPlots() {
      try {
        await xhrDelete(`/v2/ClearPlotsFromCustomerFavourites/`, {
            customerEmail: state.user.email,
            PlotIds: state.favPlotsToRemove
          })
        dispatch({ type: "remotePlotRemoved" })
      } catch (error) {
        console.log("There was a problem.")
      }
    }

    if (state.loggedIn && state.favPlotsToRemove.length > 0) {
      removeRemoteFavPlots()
    }
  }, [state.favRemoteUpdate])

  //disable right click
  if (state.environment === 'production')
  {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }

  //activate LogRocket
  useEffect(() => {
    const logRocketId = window['runConfig'].logRocketId
    if (logRocketId && cookies.inMarketingSuite === "true") {
      LogRocket.init(logRocketId)
    }
  }, [])

  //block text selection in marketing suite mode
  useEffect(() => {
    if (cookies.inMarketingSuite === "true") {
      document.body.classList.add('noselect')
    } else {
      document.body.classList.remove('noselect')
    }
  }, [])

  //handle logged in state
  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem("user", JSON.stringify(state.user))
    } else {
      localStorage.removeItem("user")
    }
  }, [state.loggedIn, state.user])

  function Settings(props){
    return(
      <CookiesProvider>
        
        {cookies.inMarketingSuite === "true"  && <IdleTimerContainer />}

        {(cookies.haveLights === "true" && cookies.lightServerAddress) && 
        <LightServer lightServerAddress={cookies.lightServerAddress} />}

      </CookiesProvider>
    )
  }

  if (!state.allLoaded) {
    return <Spinner height="100vh" />
  } else {
    return (
      <>
        <Helmet>
          <title>{`${state.devName.name} - ${state.clientName.clientName}`}</title>
        </Helmet>
        <Theme />
        <BrowserRouter>
          <Header />
          <AppRoutes />
          <CheckDevice />
          <ThinkSWController />
          <Settings />
          <Footer />
        </BrowserRouter>
      </>
    )
  }
}



export default App
