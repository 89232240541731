import React from 'react'

import '../assets/styles/components/ArrowIcon.scss'
import { ReactComponent as Icon } from '../assets/img/arrow-right.svg'

function ArrowIcon({ className = '', ...props }) {
  return (
    <span className={`ArrowIcon ${className}`}>
      <Icon />
    </span>
  )
}

export default ArrowIcon
