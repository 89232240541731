import React, { useContext } from "react"
import { Link } from "react-router-dom"

import { Context } from "../contexts/Store"
import { priceFormatter, dynamicSort, getStatus } from "../helpers/utilities"

import Meta from "./Meta"
import AvailabilityBadge from "./AvailabilityBadge"
import FavButton from "../components/FavButton"
import ToggleButton from "./ToggleButton"

import "../assets/styles/components/PlotCard.scss"
import ArrowIcon from "./ArrowIcon"

function PlotCard({ item, close }) {
  const [state, dispatch] = useContext(Context)

  let status = getStatus(state.plotStatuses, "id", item.plotStatusId)

  let image = item.plotType.assets.slice().sort(dynamicSort("order"))[0]

  return (
    <section className="PlotCard">
      <div className="PlotCard__Header">
        <div className="PlotCard__Intro">
          <h2 className="PlotCard__Number">
            <Link to={`/homes/${item.id}`}>Plot {item.plotNumber}</Link>
          </h2>
          <h3 className="PlotCard__Name">{item.name}</h3>
        </div>
        <div className="PlotCard__Availability">
          <AvailabilityBadge status={status} />

          <p className="PlotCard__Price">
            {status.name === "Available" ? priceFormatter(item.price) : null}
            {status.name === "Unreleased" ? "£POA" : null}
          </p>
        </div>
      </div>

      <div className="PlotCard__Main">
        <div className="PlotCard__Meta">
          <Meta
            beds={item.plotType.numberOfBeds}
            area={item.plotType.imperialArea}
            display="list"
          />
        </div>
        <div className="PlotCard__Image">
          {image && <img src={image.fileUrl} alt={image.name} loading="lazy" />}
        </div>
      </div>

      <div className="PlotCard__Actions">
        {close && (
          <ToggleButton
            variant="secondary"
            size="sm"
            icon="close"
            onClick={close}
          />
        )}
        <FavButton plotID={item.id} />
        <Link className="HomeLink" to={`/homes/${item.id}`}>
          View Home <ArrowIcon />
        </Link>
      </div>
    </section>
  )
}

export default PlotCard
