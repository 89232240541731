import React, { useContext, useRef, useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { usePrevious } from "../helpers/utilities"

import { Context } from "../contexts/Store"

import PlotLink from "./PlotLink"
import Button from "./Button"
import ToggleButton from "./ToggleButton"

import "../assets/styles/components/GlobalFavourites.scss"

function GlobalFavourites() {
  const [state, dispatch] = useContext(Context)

  const [showFavourites, setShowFavourites] = useState(false)

  /**
   * Expand the favourites tray when favouriting a plot for the first time
   * usePrevious to check the count is going from 0 -> 1, and not 2 -> 1
   */
  const previousCount = usePrevious(state.favPlots.length)
  useEffect(() => {
    if (state.favPlots.length === 1 && previousCount === 0) {
      setShowFavourites(true)
    }
  }, [state.favPlots])

  /*
   * Handle click outside
   */
  const ref = useRef(null)
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowFavourites(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  let animateStyle = { originX: 1, originY: 1 }

  if (state.isMobileDevice) {
    animateStyle = { originX: 0, originY: 1 }
  }

  return (
    <>
      {state.favPlots.length > 0 ? (
        <div className="GlobalFavourites" ref={ref}>
          <AnimatePresence>
            {showFavourites && (
              <motion.div
                className="GlobalFavourites__Favourites"
                style={animateStyle}
                initial={{ opacity: 0, y: 10, scale: 0.5 }}
                animate={{ opacity: 1, y: -5, scale: 1 }}
                exit={{ opacity: 0, y: 10, scale: 0.5 }}
                transition={{
                  duration: 0.25,
                  ease: [0.6, 0.01, -0.05, 0.95]
                }}
              >
                <div className="GlobalFavourites__List">
                  {state.favPlots.map((id, i) => {
                    const plot = state.plots.find((plot) => id === plot.id)
                    if (plot) {
                      return <PlotLink plot={plot} key={i} />
                    }
                    return null
                  })}
                </div>
                <div className="GlobalFavourites__Actions">
                  <Button to="favourites" size="xs" onClick={()=>setShowFavourites(false)}>
                    View all
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <ToggleButton
            icon={showFavourites ? "heart" : "heart-light"}
            onClick={() => setShowFavourites(!showFavourites)}
            count={state.favPlots.length}
          />
        </div>
      ) : null}
    </>
  )
}

export default GlobalFavourites
