export default function Reducer(draft, action) {
  switch (action.type) {
    case "SET_API_DATA":
      draft[action.key] = action.payload
      return

    case "SET_API_LOADED":
      draft.loaded = [...draft.loaded, action.key]
      return

    case "SET_API_ERROR":
      draft.error = { ...draft.error, [action.key]: true }
      return

    case "SET_API_ALL_LOADED":
      draft.allLoaded = true

    case "SET_TYPES_DATA":
      draft.types = action.payload
      return

    case "SET_MENU_DATA":
      draft.menuItems = action.payload
      return

    case "SET_PLOT_VRS":
      draft.plotVRs = action.data
      return

    case "isPanning":
      draft.isPanning = action.data
      return

    case "showPlotPreview":
      draft.isPreviewPanelOpen = true
      draft.selectedPlotId = action.data.plotid
      draft.selectedPlotMarkerId = action.data.markerId
      return

    case "closePlotPreview":
      draft.isPreviewPanelOpen = false
      return

    case "setActiveType":
      draft.activeType = action.data
      return

    case "setPlotStatuses":
      draft.plotStatuses = action.data
      return

    case "setFiltersOpen":
      draft.isFiltersOpen = action.data
      return

    case "setMenuOpen":
      draft.isMenuOpen = action.data
      return

    case "setMenuItems":
      draft.menuItems = action.data
      return

    case "setSpinMarkerData":
      draft.spinMarkerData.push(action.data)
      return

    case "setSpinConfigData":
      draft.spinConfigData.push(action.data)
      return

    case "setGlobalConfigData":
      draft.globalConfigData = action.data
      return

    case "isVrpersonActive":
      draft.isVrpersonActive = action.data
      return

    case "setAvailableHomes":
      draft.availableHomes = action.data
      return

    case "setFilteredHomes":
      draft.filteredHomes = action.data
      return

    case "setFilters":
      draft.filters = action.data
      return

    case "setRangeLabels":
      draft.rangeLabels = action.data
      return

    case "addFavPlot":
      let plotToAdd = action.data

      // If favPlots exist, add the new plot
      if (draft.favPlots) {
        // use Set to ensure no duplicates
        draft.favPlots = [...new Set([plotToAdd, ...draft.favPlots])]

        // Else instantiate the favPlots array
      } else {
        draft.favPlots = [plotToAdd]
      }

      // add to queue to update remote favs
      if (draft.favPlotsToAdd) {
        draft.favPlotsToAdd = [
          ...new Set([...draft.favPlotsToAdd, plotToAdd])
        ]
      } else {
        draft.favPlotsToAdd = [plotToAdd]
      }

      draft.favRemoteUpdate++
      return

    case "removeFavPlot":
      let plotToRemove = action.data

      if (draft.favPlots) {
        // Filter the specified plot from the current favourites
        draft.favPlots = draft.favPlots.filter((plot) => {
          if (plot !== plotToRemove) {
            return plot
          }
          return null
        })

        // Add to queue to remove from remoteFavs
        if (draft.favPlotsToRemove) {
          draft.favPlotsToRemove = [
            ...new Set([...draft.favPlotsToRemove, plotToRemove])
          ]
        } else {
          draft.favPlotsToRemove = [plotToRemove]
        }

        // Trigger remoteFavs update
        draft.favRemoteUpdate++
      }
      return

    case "clearFavPlots":
      draft.favPlots = []
      return

    case "syncFavPlots":
      if (draft.favPlots) {
        let newFavs = [...new Set([...draft.favPlots, ...action.data])]
        draft.favPlots = newFavs
        draft.favPlotsToAdd = newFavs
        draft.favRemoteUpdate++
      } else {
        draft.favPlots = action.data
      }
      return

    case "updateLocalFavPlots":
      draft.favPlots = action.data
      return

    case "remotePlotRemoved":
      draft.favPlotsToRemove = []
      return

    case "remotePlotAdded":
      draft.favPlotsToAdd = []
      return

    case "updateRemoteFavPlots":
      draft.favPlotsToAdd = draft.favPlots
      draft.favRemoteUpdate++
      return

    case "login":
      draft.loggedIn = true
      draft.user = action.data
      return

    case "logout":
      draft.loggedIn = false
      return

    case "setCurrentLight":
      draft.currentLight = action.data
      return

    case "turnOffCurrentLight":
      draft.currentLight.status = "off"
      return

    case "turnOnLightsAttractor":
      draft.currentLight.status = "attractor"
      return

    case "setSpinAssetsForDevice":
      draft.spinAssetsForDevice = action.data
      return

    case "setIsMobileDevice":
      draft.isMobileDevice = action.data
      return

      case "setIsTabletDevice":
      draft.isTabletDevice = action.data
      return

    case "setSupportsWebp":
      draft.supportsWebp = true
      return

    default:
      return draft
  }
}
