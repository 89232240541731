import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { getStatus, priceFormatter } from "../helpers/utilities"

import { Context } from "../contexts/Store"

import AvailabilityBadge from "./AvailabilityBadge"
import ArrowIcon from "./ArrowIcon"
import FavButton from "./FavButton"

import "../assets/styles/components/PlotLink.scss"

function PlotLink({ plot }) {
  const [state, dispatch] = useContext(Context)

  let status = getStatus(state.plotStatuses, "id", plot.plotStatusId)

  return (
    <div className="PlotLink">
      <div className="PlotLink__Main">
        <Link to={`/homes/${plot.id}`} className="PlotLink__Name">
          Plot {plot.plotNumber}
        </Link>
        {plot.price > 0 && (
          <p className="PlotLink__Price">{priceFormatter(plot.price)}</p>
        )}
        <AvailabilityBadge status={status} />
      </div>
      <div className="PlotLink__Actions">
        <FavButton plotID={plot.id} />
        <ArrowIcon />
      </div>
    </div>
  )
}

export default PlotLink
