import React, { useContext, useEffect, useState } from "react"
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { NavLink } from 'react-router-dom'

import { Context } from "../contexts/Store"

import '../assets/styles/components/Breadcrumbs.scss'

function Breadcrumbs() {

  const [state] = useContext(Context)
  const [containerNameAndId, setContainerNameAndId] = useState({})
  let tempObj = {}
  
  useEffect(() => {
    
    if(state.plotContainers)
    getPlotContainerDataForId(state.plotContainers)
    
  }, [state.plotContainers])
  

  function getPlotContainerDataForId(obj) {
 
    obj.forEach(function (plotContainerData){
      
      let thisPlotContainersData = plotContainerData.plotContainers
      tempObj[plotContainerData.id] = plotContainerData.name
      
      thisPlotContainersData.forEach(function(thisContainer) {
        tempObj[thisContainer.id] = thisContainer.name
        
      })
    
      if (plotContainerData.hasOwnProperty('plotContainers'))
        getPlotContainerDataForId(plotContainerData.plotContainers)
    })
    setContainerNameAndId(tempObj)

  }
  
 
  const DynamicUserBreadcrumb = ({ match }) => (
    <span>{containerNameAndId[match.params.id]}</span>
  )


let masterplanRoutes = [
  { path: '/masterplan', breadcrumb: 'Full Site' },
  { path: '/siteplanspin/:id', breadcrumb: 'Full Site' },
  { path: '/masterplan/:id', breadcrumb: DynamicUserBreadcrumb },
  { path: '/masterplan/:id/:id', breadcrumb: DynamicUserBreadcrumb },
  { path: '/masterplan/:id/:id/:id', breadcrumb: DynamicUserBreadcrumb },
  { path: '/siteplanspin/:id/:id', breadcrumb: DynamicUserBreadcrumb },
  { path: '/siteplanspin/:id/:id/id', breadcrumb: DynamicUserBreadcrumb },
  { path: '/', breadcrumb: 'Home' }
]

  const breadcrumbs = useBreadcrumbs(masterplanRoutes)

  return (
    <nav className="Breadcrumbs">
      <ul className="Breadcrumbs__List">
        {breadcrumbs.map(({ match, breadcrumb }, i) => {
          if (breadcrumb.props.children !== 'Home') {
            if (breadcrumb.props.children !== 'Siteplanspin') {
            return (
              <span className="Breadcrumbs__Item Breadcrumbs__Item--Link" key={match.pathname}>
                <NavLink className="Breadcrumbs__Link" to={match.pathname}>{breadcrumb}</NavLink>
              </span>
            )
          }}
          return
        })}
      </ul>
    </nav>
  )

}

export default Breadcrumbs
