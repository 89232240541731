import React, { useContext } from "react"
import { Link, useLocation } from "react-router-dom"

import { Context } from "../contexts/Store"
import {
  priceFormatter,
  dynamicSort,
  getStatus,
  stringToSlug
} from "../helpers/utilities"

import Meta from "./Meta"
import AvailabilityBadge from "./AvailabilityBadge"
import FavButton from "../components/FavButton"

import "../assets/styles/components/PlotCardPreview.scss"
import ArrowIcon from "./ArrowIcon"
import ActionButton from "./ActionButton"
import ToggleButton from "./ToggleButton"
import InteriorTourButton from "../components/InteriorTourButton"

function PlotCardPreview({ item, variant = "normal", close }) {
  const [state, dispatch] = useContext(Context)
  const location = useLocation()

  let status = getStatus(state.plotStatuses, "id", item.plotStatusId)

  let images = item.plotType.assets.slice().sort(dynamicSort("order"))

  let image = images[0]

  let phase = null
  if (item.customFields && item.customFields.length > 0) {
    phase = item.customFields.find(
      ({ displayName }) => stringToSlug(displayName) === `phase`
    )
  }

  let interiorTour = null
  const typeSlug = stringToSlug(item.plotType.name)
  if (state.menuItems.interiorTours) {
    interiorTour = state.menuItems.interiorTours.find(
      (el) => el.slug === typeSlug
    )
  }

  let exteriorTourView = null
  if (state.plotVRs) {
    exteriorTourView = state.plotVRs.find(
      ({ plot }) => stringToSlug(plot) === `plot-${item.plotNumber}`
    )
  }

  return (
    <section className="PlotCardPreview">
      <div className="PlotCardPreview__ImageHeader">
        {image && <img src={image.fileUrl} alt={image.name} loading="lazy" />}
      </div>

      <div className="PlotCardPreview__Main">
        <div className="PlotCardPreview__Header">
          <div className="PlotCardPreview__Intro">
            <h2 className="PlotCardPreview__Number">
              <Link to={`/homes/${item.id}`}>Plot {item.plotNumber}</Link>
            </h2>
            <h3 className="PlotCardPreview__Name">{item.name}</h3>
          </div>
          <div className="PlotCardPreview__Availability">
            <AvailabilityBadge status={status} />

            <p className="PlotCardPreview__Price">
              {status.name === "Available" ? priceFormatter(item.price) : null}
              {status.name === "Unreleased" ? "£POA" : null}
            </p>
          </div>
        </div>

        <div className="PlotCardPreview__Meta">
          <Meta
            beds={item.plotType.numberOfBeds}
            area={item.plotType.imperialArea}
            display="row"
          />
        </div>

        <div className="PlotCardPreview__Extras">
          <InteriorTourButton tour={interiorTour} />

          {exteriorTourView && !location.pathname.includes('exterior-tour') && (
            <ActionButton
              icon="streetview"
              to={`/exterior-tour/${
                phase ? stringToSlug(phase.displayName) : "current"
              }/${exteriorTourView.arg}`}
            >
              View Street
            </ActionButton>
          )}
        </div>

        <div className="PlotCardPreview__Actions">
          <ToggleButton
            variant="secondary"
            size="sm"
            icon="close"
            onClick={close}
          />
          <FavButton plotID={item.id} />
          <Link className="HomeLink" to={`/homes/${item.id}`}>
            View Home <ArrowIcon />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default PlotCardPreview
