import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../assets/styles/components/Flag.scss'

import image1 from '../assets/img/flag-1.jpg'
import image2 from '../assets/img/flag-2.jpg'
import image3 from '../assets/img/flag-3.jpg'

function Flag({ imageID = '1', align = 'left', ...props }) {

  const { ref, inView } = useInView({
    threshold: 0.25
  })

  let image
  switch(imageID) {
    case '1':
      image = image1
      break
    case '2':
      image = image2
      break
    case '3':
      image = image3
      break
  }

  return (
    <section ref={ref} className={`Flag Flag--${align} animate ${inView ? 'active' : ''}`}>
        <div className="Flag__image">
          <img src={image} alt="" />
        </div>
        <div className="Flag__content">
          {props.children}
        </div>
      </section>
  )
}

export default Flag