import React, { useState, useContext, useRef, useEffect } from 'react'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { useLocation, NavLink, Link } from 'react-router-dom'

import { Context } from "../contexts/Store"

import '../assets/styles/components/Menu.scss'

function Menu() {

  const [state, dispatch] = useContext(Context)
  const wrapperRef = useRef(null)

  const logo = state.devStyle.images.additionalLogoUrl

  const items = [
    {
      label: 'Homes',
      path: '/homes'
    },
    {
      label: 'Siteplan',
      path: '/masterplan'
    },
    {
      label: 'Local Area',
      path: '/local-area'
    },
    {
      label: 'Gallery',
      path: '/gallery'
    },
  ]

  if (state.menuItems.brochures) {
    items.push({
        label: 'Brochures',
        path: '/brochures'
      })
  }

  if (state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 0 ) {
    items.push({
      label: 'Exterior Tour',
      path: '/exterior-tour'
      })
  }


  /*
   * Set active nav link
   */
  const [selectedItem, setSelectedItem] = useState()
  const location = useLocation()

  let tourObject = {
    mouseOver : false
  }

  useEffect(() => {
    
    let thisPath = location.pathname
    
    if(thisPath.includes('siteplanspin'))
    thisPath = '/masterplan'
  
    let activeItem = items.find(item => thisPath.includes(item.path)) || false
    setSelectedItem(activeItem)

    if (state.isMobileDevice) {
      dispatch({
        type: 'setMenuOpen',
        data: false
      })
    }

  }, [location.pathname])

  /*
   * Handle click outside
   */
  const containerRef = useRef(null)
  const buttonRef = useRef(null)

  const handleClickOutside = (e) => {
    if(
      containerRef.current && !containerRef.current.contains(e.target) &&
      buttonRef.current && !buttonRef.current.contains(e.target)
    ) {
      if(state.isMenuOpen) {
        dispatch({
          type: 'setMenuOpen',
          data: false
        })
      }
    }
  }
  
  useEffect(()=>{
    
    if(document.getElementById('virtualtour')){
      document.getElementById('virtualtour').addEventListener('mouseover',function(){
        tourObject.mouseOver = true
      })
    }
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true)
    window.addEventListener('blur', checkForControlInIframe, true)
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
      window.removeEventListener('blur', checkForControlInIframe, true)
    }
  
  })

  const checkForControlInIframe = (event) => {
    if(tourObject.mouseOver)
    closeMenu()
  }

  function closeMenu(){
    if(state.isMenuOpen) {
      dispatch({
        type: 'setMenuOpen',
        data: false
      })
    }
  }

  return (
    <AnimateSharedLayout>
      <div className="Menu" ref={wrapperRef}>
        <button
          className={`Menu__Toggle ${state.isMenuOpen ? 'active' : ''}`}
          onClick={() => dispatch({
            type: 'setMenuOpen',
            data: !state.isMenuOpen
          })}
          aria-controls="#menu"
          ref={buttonRef}
        >
          <span className="Menu__Burger">
            <span className="Menu__Burger__line Menu__Burger__line--1"></span>
            <span className="Menu__Burger__line Menu__Burger__line--2"></span>
            <span className="Menu__Burger__line Menu__Burger__line--3"></span>
          </span>
          Menu
        </button>
        <AnimatePresence>
        {state.isMenuOpen && 
          <motion.div
            className="Menu__Container"
            style={{ originX: 0.95, originY: 0.95 }}
            initial={{ opacity: 0, y: 10, scale: 0.5 }}
            animate={{ opacity: 1, y: -5, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.5 }}
            transition={{
              duration: 0.25,
              ease: [0.6, 0.01, -0.05, 0.95]
            }}
            ref={containerRef}
          >
            {logo && state.isMobileDevice && (
              <>
                <Link to="/">
                  <img src={logo} alt="" className="Menu__Logo" loading="lazy" />
                </Link>
                <p className="Menu__Development">{state.devName.name}</p>
              </>
            )}
            <motion.ul
              id="menu"
              className="Menu__Items"
            >
              {items.map((item, i) => {
                const isSelected = (selectedItem.path === item.path)

                return (
                  <li
                    key={i}
                    className="Menu__Item"
                    onClick={() => setSelectedItem(item)}
                  >
                    {isSelected && 
                      <motion.div
                        layoutId="Menu__Bubble"
                        className="Menu__Bubble"
                        initial={false}
                        animate={{ backgroundColor: 'var(--theme-primary-color) '}}
                      />
                    }
                    {item.path.includes('http') ?
                      <a href={item.path} target="_blank">{item.label}</a>
                    : 
                      <NavLink to={item.path}>{item.label}</NavLink>
                    }
                  </li>
                )
              })}
            </motion.ul>
          </motion.div>
        }
        </AnimatePresence>
      </div>
    </AnimateSharedLayout>
  )
}

export default Menu
