import { React, useState, useContext } from 'react'
import { Context } from "../contexts/Store"
import { Helmet } from "react-helmet"

import Button from '../components/Button'
import IconButton from '../components/IconButton'
import HomeFilters from '../components/HomeFilters'
import BackButton from '../components/BackButton'

import '../assets/styles/pages/Home.scss'

function Home() {

  const [activeView, setActiveView] = useState('splash')
  const [state, dispatch] = useContext(Context)


  return (
    <div className="Home">

      <Helmet>
        <title>{`${state.devName.name} - ${state.clientName.clientName}`}</title>
      </Helmet>

      {state.devStyle.images.wallIdleVideoUrl && !state.isMobileDevice && (
        <div className="Home__Media Home__Media--video">
          <video
            className="Home__Video"
            playsInline="playsinline"
            autoPlay="autoplay"
            muted="muted"
            loop="loop"
          >
            <source
              src={state.devStyle.images.wallIdleVideoUrl}
              type="video/mp4"
            />
          </video>
        </div>
      )}

      {state.devStyle.images.backgroundImageUrl && (!state.devStyle.images.wallIdleVideoUrl || state.isMobileDevice) && (
        <div className="Home__Media Home__Media--image">
          <img src={state.devStyle.images.backgroundImageUrl} alt="" />
        </div>
      )}

      {activeView === 'splash' && (
        <div className="Home__Content">
          <img src={state.devStyle.images.wallLogoUrl} alt="" className="Home__DevLogo" />
          <Button variant="primary" to="brochure">Take a Look</Button>
        </div>
      )}

      {activeView === 'buttons' && (
        <div className="Home__ButtonGroup">
          <div className="Home__ButtonGroup__Buttons">
            <IconButton to="masterplan" icon="search">Siteplan</IconButton>
            {state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 0 && (
              <IconButton to="exterior-tour" icon="street-view" href="exterior-tour">View Street</IconButton>
            )}
            <IconButton icon="filters" onClick={() => setActiveView('filters')}>Filter Homes</IconButton>
          </div>
          <BackButton onClick={() => setActiveView('splash')} />
        </div>
      )}

      {activeView === 'filters' && ( 
        <div className="Home__Filters">
          <HomeFilters />
          <BackButton onClick={() => setActiveView('buttons')} />
        </div>
      )}
    </div>
  )
}

export default Home
