import { React, useState, useContext, useEffect, Suspense } from "react"
import { Helmet } from "react-helmet"

import { get, post } from "../adapters/xhr"
import { Context } from "../contexts/Store"
import Fancybox from "../components/Fancybox"
import { SuspenseImg } from "../components/SuspenseImage"
import SubMenu, { SubMenuSelect} from "../components/SubMenu"

import "../assets/styles/pages/Gallery.scss"
import { ReactComponent as PlayIcon } from "../assets/img/play-circle-duotone.svg"

function Gallery() {
  const [activeView, setActiveView] = useState("splash")
  const [state, dispatch] = useContext(Context)

  const [galleryData, setGalleryData] = useState()
  const [galleryKey, setGalleryKey] = useState(0)

  //get the gallery data
  useEffect(() => {
    get(`v1/GetGalleries/${state.devId}/true`).then((response) => {
      if (response.data) {
        setGalleryData(response.data)
      }
    })
  }, [state.appLoaded])

  function changeGallery(key) {
    setGalleryKey(key)
  }

  function LoadingImages() {
    return (
      <div className="Gallery__Grid">
        {galleryData && galleryData[galleryKey].assets
          ? galleryData[galleryKey].assets.map((asset, assetsIndex) => {
              return (
                <div
                  className="Gallery__Item Gallery__Item--skeleton"
                  key={`${galleryKey}_${assetsIndex}`}
                ></div>
              )
            })
          : null}
      </div>
    )
  }

  function ShowImages() {
    return (
      <Fancybox
        options={{
          Toolbar: false,
          closeButton: "outside",
          Image: {
            zoom: false
          }
        }}
      >
        <div className="Gallery__Grid">
          {galleryData &&
          galleryData[galleryKey] &&
          galleryData[galleryKey].assets
            ? galleryData[galleryKey].assets.map((asset, assetsIndex) => {
                if (
                  asset.fileUrl.endsWith("jpg") ||
                  asset.fileUrl.endsWith("jpeg")
                ) {
                  return (
                    <a
                      className="Gallery__Item"
                      key={`${galleryKey}_${assetsIndex}`}
                      data-fancybox={`gallery_${galleryKey}`}
                      href={asset.fileUrl}
                      data-caption={asset.name}
                    >
                      <div className="Gallery__Item__Caption">
                        {asset.name}
                      </div>
                      <SuspenseImg alt={asset.name} src={asset.fileUrl} />
                    </a>
                  )
                } else if (asset.fileUrl.endsWith("mp4")) {
                  return (
                    <a
                      className="Gallery__Item Gallery__Item--video"
                      key={`${galleryKey}_${assetsIndex}`}
                      data-fancybox={`gallery_${galleryKey}`}
                      href={asset.fileUrl}
                    >
                      <div className="Gallery__Item__PlayIcon">
                        <PlayIcon />
                      </div>

                      <video width="400" preload="metadata">
                        <source src={asset.fileUrl} type="video/mp4" />
                      </video>
                    </a>
                  )
                } else {
                  return null
                }
              })
            : null}
        </div>
      </Fancybox>
    )
  }

  return (
    <div className="Gallery">
      <Helmet>
        <title>{`Gallery - ${state.devName.name}`}</title>
      </Helmet>
        
      {galleryData && galleryData.length > 1 ? (
        <SubMenu>
          <SubMenuSelect list={galleryData} value={galleryKey} onChange={(e) => changeGallery(e.target.value)}></SubMenuSelect>
        </SubMenu>
      ) : null}

      <Suspense fallback={<LoadingImages />}>
        {galleryData ? <ShowImages /> : null}
      </Suspense>
    </div>
  )
}

export default Gallery
