import React from 'react'

import '../assets/styles/components/Alert.scss'

function Alert({ variant = 'default', ...props }) {
  return (
    <div className={`Alert Alert--${variant}`}>
      {props.children}
    </div>
  )
}

export default Alert
