import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../assets/styles/components/Overlay.scss'
import image1 from '../assets/img/overlay.jpg'
import image2 from '../assets/img/overlay-2.jpg'
import image3 from '../assets/img/overlay-3.jpg'

function Overlay({ imageID = '1', align = 'right', ...props }) {

  const { ref, inView } = useInView({
    threshold: 0.75
  })

  let image
  switch(imageID) {
    case '1':
      image = image1
      break
    case '2':
      image = image2
      break
    case '3':
      image = image3
      break
  }

  return (
    <section ref={ref} className={`Overlay animate ${inView ? 'active' : ''}`}>
      <div className="Overlay__image">
        <img src={image} alt="" />
      </div>
      <div className={`Overlay__content Overlay__content--${align}`}>
        {props.children}
      </div>
    </section>
  )
}

export default Overlay
