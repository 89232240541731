import React from 'react'

import '../assets/styles/components/Meta.scss'

import { ReactComponent as IconBeds } from '../assets/img/beds.svg'
import { ReactComponent as IconArea } from '../assets/img/ruler.svg'
import { ReactComponent as IconPlot } from '../assets/img/house.svg'

//display = bigRow (on types), row, list

function Meta({ beds, area, type, display = 'bigRow' }) {
  return (
    <ul className={`Meta Meta--${display}`}>
      {beds &&
        <li>
          <span className="Meta__Icon"><IconBeds /></span>
          <span className="Meta__Label">{beds} {parseInt(beds) > 1 ? 'beds' : 'bed'}</span>
        </li>
      }
      {area &&
        <li>
          <span className="Meta__Icon"><IconArea /></span>
          <span className="Meta__Label">{area} sq ft</span>
        </li>
      }
      {type && 
        <li>
          <span className="Meta__Icon"><IconPlot /></span>
          <span className="Meta__Label">{type}</span>
        </li>
      }
    </ul>
  )  
}

export default Meta
