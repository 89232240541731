import React, { useContext } from 'react'
import { Context } from "../contexts/Store"
import { Link } from 'react-router-dom'

import '../assets/styles/components/Header.scss'

function Header() {

  const [state, dispatch] = useContext(Context)

  return (
    <header className="Header">
      {!state.isMobileDevice && (
        <>
          <Link to="/" className="Header__Name">{state.clientName.clientName}</Link>
          <p className="Header__Development">{state.devName.name}</p>
        </>
      )}
    </header>
  )
}

export default Header
