import React, { useState, useEffect, useRef } from "react"

import { ReactComponent as InfoIcon } from "../assets/img/info-circle-light.svg"
import { ReactComponent as RulerIcon } from "../assets/img/ruler-combined-light.svg"

import { motion, AnimatePresence } from "framer-motion"

import "../assets/styles/components/TooltipButton.scss"

const Icon = (props) => {
  const { type } = props
  if (type === "info") {
    return <InfoIcon />
  } else if (type === "ruler") {
    return <RulerIcon />
  } else {
    return <InfoIcon />
  }
}

function TooltipButton(props) {
  const { children, title, icon } = props
  const [isOpen, setIsOpen] = useState(false)

  /*
   * Handle click outside
   */
  const ref = useRef(null)
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return (
    <div className="TooltipButton" ref={ref}>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            className="TooltipButton__Content"
            initial={{
              opacity: 0,
              y: -20
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              duration: 0.25,
              ease: [0.6, 0.01, -0.05, 0.95]
            }}
          >
            <div className="TooltipButton__Inner">{children}</div>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <button
        className={`${
          isOpen
            ? "TooltipButton__Button TooltipButton__Button--active"
            : "TooltipButton__Button"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`TooltipButton__Icon ${props.className}`}>
          <Icon type={icon} />
        </span>
        <span className="TooltipButton__Title">{title}</span>
      </button>
    </div>
  )
}

export default TooltipButton
