import React, { useState, useContext } from "react"
import { Context } from "../contexts/Store"
import { filteredCountByType } from "../helpers/utilities"
import { Helmet } from "react-helmet"
import ActiveFilters from '../components/ActiveFilters'
import Type from '../components/Type'
import PlotCard from "../components/PlotCard"
import Alert from '../components/Alert'
import SubMenu, { SubMenuViewTabs } from "../components/SubMenu"
import Gallery from '../components/Gallery'

import "../assets/styles/pages/Homes.scss"
import "../assets/styles/pages/Types.scss"

function Homes() {
  const [state, dispatch] = useContext(Context)


  const [activeView, setActiveView] = useState("types")

  const views = [
    {
      label: "Types",
      slug: "types"
    },
    {
      label: "Homes",
      slug: "homes"
    }
  ]


  return (
    <div className="Homes">

      <Helmet>
        <title>{`Homes - ${state.devName.name}`}</title>
      </Helmet>

      <SubMenu>
          <SubMenuViewTabs
            views={views}
            activeView={activeView}
            setActiveView={setActiveView}
          ></SubMenuViewTabs>
      </SubMenu>

      <div className="container">
        <ActiveFilters />

        {activeView === 'types' && <Gallery title="Find your perfect Bewley home" />}

        {!state.filteredHomes.length && (
          <Alert>No homes matched your criteria. Please try different options.</Alert>
        )}

        {activeView === 'types' && (
          <div className="Types">
            {state.types && state.types.map((item, i) => {
              if (filteredCountByType(state.filteredHomes, item) > 0) {
                return <Type item={item} key={i} />
              }
            })}
          </div>
        )}

        {activeView === "homes" && (
          <div className="Plot__Grid">
            {state.filteredHomes.map((item, i) => {
              return <PlotCard item={item} key={i} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Homes
