import React, { useContext, useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"

import { Context } from "../contexts/Store"

function CheckDevice() {
  
  const [state, dispatch] = useContext(Context)

  const isMobile = useMediaQuery({
    query: '(max-width: 740px)'
  })
  const isTablet = useMediaQuery({
    query: '(max-width: 980px)'
  })

  useEffect(() => {
    
    let tempObj = {"lowRes": "1k", "hiRes": "4k"}
  
    dispatch({
      type: "setIsTabletDevice",
      data: isTablet
    })

    dispatch({
      type: "setIsMobileDevice",
      data: isMobile
    })
  
    if(isMobile){
      tempObj.lowRes = "mobile"
      tempObj.hiRes = "2k"
    }
  
    dispatch({
      type: "setSpinAssetsForDevice",
      data: tempObj
    })
  
  }, [isMobile, isTablet])

  return null
}

export default CheckDevice
