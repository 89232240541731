import React, { useContext, useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import parse from "html-react-parser"
import { Context } from "../contexts/Store"
import {
  priceFormatter,
  dynamicSort,
  getStatus,
  stringToSlug,
  findFloorplans
} from "../helpers/utilities"
import { Helmet } from "react-helmet"
import "../assets/styles/pages/Plot.scss"
import AvailabilityBadge from "../components/AvailabilityBadge"
import Meta from "../components/Meta"
import ActionButton from "../components/ActionButton"
import TooltipButton from "../components/TooltipButton"
import BackButton from "../components/BackButton"
import FavButton from "../components/FavButton"
import Fancybox from "../components/Fancybox"
import SubMenu, { SubMenuViewTabs } from "../components/SubMenu"
import InteriorTourButton from "../components/InteriorTourButton"
import Dims from "../components/Dims"

function Plot() {
  const [state, dispatch] = useContext(Context)
  const plotID = parseInt(useParams().id)
  const [plot, setPlot] = useState()

  const [activeView, setActiveView] = useState("overview")

  const views = [
    {
      label: "Overview",
      slug: "overview"
    },
    {
      label: "Floorplans",
      slug: "floorplans"
    }
  ]

  useEffect(() => {
    state.plots.filter((el) => {
      if (el.id === plotID) {
        let images = el.plotType.assets.slice().sort(dynamicSort("order"))
        el = { ...el, images }

        //get floorplans
        let floorplans = findFloorplans(images)

        // add the floorplans
        el = { ...el, floorplans }

        //get the current plot phase
        let phase = null
        if (el.customFields && el.customFields.length > 0) {
          phase = el.customFields.find(
            ({ displayName }) => stringToSlug(displayName) === `phase`
          )
        }
        el = { ...el, phase }

        //find exterior tour view
        let exteriorTourView = null
        if (state.plotVRs) {
          exteriorTourView = state.plotVRs.find(
            ({ plot }) => stringToSlug(plot) === `plot-${el.plotNumber}`
          )
        }
        el = { ...el, exteriorTourView }

        //find interior tour
        let interiorTour = null
        const typeSlug = stringToSlug(el.plotType.name)
        if (state.menuItems.interiorTours) {
          interiorTour = state.menuItems.interiorTours.find(
            (el) => el.slug === typeSlug
          )
        }
        el = { ...el, interiorTour, typeSlug }

        setPlot(el)
      }
    })
  }, [state.plots])


  //set current light
  useEffect(() => {
    if(plot){
      dispatch({
        type: "setCurrentLight",
        data: { ID: plot.lightId, status: "on" }
      })
    }
    return()=>{
      dispatch({
        type: "turnOffCurrentLight"
      })
    }
  }, [plot])


  if (!plot) {
    return <>Loading</>
  }

  let status = getStatus(state.plotStatuses, "id", plot.plotStatusId)

  return (
    <main className="Plot">

      <Helmet>
        <title>{`Plot ${plot.plotNumber} - ${state.devName.name}`}</title>
      </Helmet>

      <SubMenu>
        {!state.isMobileDevice && (
          <>
            <div className="SubMenu__Item">
              <BackButton />
            </div>
            <div className="SubMenu__Item">
              <FavButton plotID={plot.id}>Favourite</FavButton>
            </div>
          </>
        )}
        {plot.floorplans.length > 0 && (
          <SubMenuViewTabs
            views={views}
            activeView={activeView}
            setActiveView={setActiveView}
          ></SubMenuViewTabs>
        )}
      </SubMenu>

      <header className="Plot__Header">
        <div className="Plot__Intro">
          <h1 className="Plot__Name">Plot {plot.plotNumber}</h1>
          <h2 className="Plot__Type">{plot.name}</h2>
          <p className="Plot__Price">{priceFormatter(plot.price)}</p>
          <div className="Plot__Availability">
            <AvailabilityBadge variant="secondary" status={status} />
          </div>
        </div>
        <div className="Plot__Meta">
          <Meta
            beds={plot.plotType.numberOfBeds}
            area={plot.plotType.imperialArea}
          />
        </div>
      </header>

      {state.isMobileDevice && (
        <div className="Plot__MobileActions">
          <BackButton />
          <FavButton plotID={plot.id}>Favourite</FavButton>
        </div>
      )}

      {activeView === "overview" && (
        <div className="Plot__Main">
          <div className="Plot__Gallery">
            <div className="Plot__Images">
              {plot.images.map((image, i) => {
                if (image.name.indexOf('FP_') != -1) return // Omit floorplan images
                return (
                  <div className="Plot__Image" key={i}>
                    <img src={image.fileUrl} alt={image.name} loading="lazy" />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="Plot__Description">
            {parse(plot.description)}

            {plot.interiorTour && (
              <div className="Plot__Actions">
                <InteriorTourButton tour={plot.interiorTour} />

                {plot.exteriorTourView && (
                  <ActionButton
                    variant="secondary"
                    icon="streetview"
                    to={`/exterior-tour/${
                      plot.phase
                        ? stringToSlug(plot.phase.displayName)
                        : "current"
                    }/${plot.exteriorTourView.arg}`}
                  >
                    View Street
                  </ActionButton>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {activeView === "floorplans" && (
        <div className="Plot__Floorplans">
          <div className="Plot__Gallery">
            <div className={`Plot__Images ${plot.floorplans.length < 3 ? 'Plot__Images--centered' : ''}`}>
              {plot.floorplans.map((image, i) => {
                return (
                  <div className="Plot__Image" key={i}>
                    <img src={image.fileUrl} alt={image.name} loading="lazy" />
                    <div className="Caption">{image.caption}</div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="Plot__ActionsRow">
            <TooltipButton title="Dimensions" icon="ruler">
            <Dims data={plot.plotType} compareCard={false}/>
            </TooltipButton>
          </div>
        </div>
      )}
    </main>
  )
}

export default Plot