import React, { useContext, useState, useEffect, useRef } from "react"
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch"
import { motion, AnimatePresence } from "framer-motion"
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"

import { Context } from "../contexts/Store"
import { getPlotById, getStatusID } from '../helpers/utilities'

import AptMarkerMP from "../components/AptMarkerMP"
import PlotMarkerMP from "../components/PlotMarkerMP"
import PlotPreview from "../components/PlotPreview"
import MapControl from "../components/MapControl"
import AvailabilityBadge from "../components/AvailabilityBadge"
import Alert from "../components/Alert"
import Breadcrumbs from "../components/Breadcrumbs"
import SubMenu from "../components/SubMenu"

import "../assets/styles/components/Masterplan.scss"

function Masterplan() {

  let [state, dispatch] = useContext(Context)
  const transformComponentRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  let id = useParams().id

  const [blockPlanImage, setBlockplanImage] = useState('')
  const [aptMarkers, setAptMarkers] = useState([])
  const [plotMarkers, setPlotMarkers] = useState([])
  const [thisBuildingName, setThisBuildingName] = useState('')
  const [cgiImage, setCgiImage] = useState('')
  const [cgiImagePath, setCgiImagePath] = useState('')
  const [displayCgiImage, setDisplayCgiImage] = useState(false)

  const [floorsArray, setFloorsArray] = useState([])
  const [floorLabel, setFloorLabel] = useState("")

  const [scale, setScale] = useState(1.0)

  const [selectedTargetId, setSelectedTargetId] = useState()
  const [containerType, setContainerType] = useState()

  const [currentFloorNumber, setCurrentFloorNumber] = useState(0)
  const [prevFloorNumber, setPrevFloorNumber] = useState(0)
  const [floorAvailabilityCount, setFloorAvailabilityCount] = useState(0)
  const [showElevation, setShowElevation] = useState(!state.isMobileDevice)

  const [floorDirection, setDirection] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const [displayBreadcrumbs, setDisplayBreadcrumbs] = useState(false)
  

  let interactiveTimer
  let sitePlanSize
  let displayBreadrumbs

  const variants = {
    enter: (floorDirection) => {

      return {
        zIndex: 1,
        y: floorDirection < 0 ? "50%" : "-50%",
        scale: 0.4,
        opacity: 0.2
      }
    },
    center: {
      y: 0,
      scale: `${containerType}`.includes("Block") ? 0.75 : 1.0,
      opacity: 1.0
    },
    exit: () => {
      return {
        zIndex: 0,
        y: floorDirection > 0 ? "50%" : "-50%",
        scale: 0.4,
        opacity: 0.2
      }
    }
  }

  useEffect(()=>{
    if(state.isMobileDevice){
      document.documentElement.style.setProperty("--frame-width", 500 )
      sitePlanSize = 700
    }else{
      document.documentElement.style.setProperty("--frame-width", 1000 )
      sitePlanSize = 2000
    }
  },[state.isMobileDevice])



  useEffect(() => {
    setIsMounted(true)
    
    window.addEventListener('resize', (event) => {
      CenterView()
    })

    return () => {     
      setIsMounted(false) 

      if(!location.pathname.includes("masterplan"))
        document.documentElement.style.setProperty("--frame-width", sitePlanSize )
      
      dispatch({ type: "closePlotPreview" })

      window.removeEventListener('resize', (event) => {
        CenterView()
      })
    }
  }, [])

  useEffect(()=>{
    if(id === undefined)
    id = 0
  
    const pathArray = location.pathname.split("/")
    if(pathArray.length > 2){
      setDisplayBreadcrumbs(true)
    }else{
      setDisplayBreadcrumbs(false)
    }

  if(state.plotContainers){
    DisplaySite()
  }

  },[location])

  useEffect(() => {
    zoomToPlotMarker()
  }, [state.selectedPlotMarkerId])

  const animateFloorplan = (newDirection) => {
    setDirection(newDirection)
  }

  useEffect(() => { 
    if(scale == 1)
      dispatch({ type: "closePlotPreview" })
  }, [scale])

  useEffect(() => {
    CenterView()
  }, [blockPlanImage])

  useEffect(() => {
    setCgiImage(cgiImagePath)
  }, [displayCgiImage])

  useEffect(() => {
    if(currentFloorNumber < prevFloorNumber) {
      animateFloorplan(-1)
    } else {
      animateFloorplan(1)
    }
    setPrevFloorNumber(currentFloorNumber)
  }, [currentFloorNumber])

  useEffect(() => {
    if(!state.isPreviewPanelOpen)
      CenterView()
  }, [state.isPreviewPanelOpen])

  useEffect(() => {
      getPlotContainerDataForId(state.plotContainers, selectedTargetId)
  }, [state.filteredHomes])

  useEffect(() => {
    const floor = floorsArray.find((item) => {
      return item.targetId == selectedTargetId
    })
    if (floor) {
      setFloorAvailabilityCount(floor.availabilityCount)
    }
  }, [selectedTargetId])

  function getPlotContainerDataForId(obj, targetId){

      obj.forEach(function (plotContainerData){
        if (plotContainerData.id == targetId){
          let imageUrl = plotContainerData.interactiveAssetURI
          let interactiveRegions = plotContainerData.interactiveRegions
          let containerType = plotContainerData.plotContainerType.name

          setContainerType(containerType)
          setCgiImagePath(plotContainerData.cgiAssetURI)

          if(plotContainerData.name != 'Site Plan'){
            setFloorLabel(plotContainerData.name)
            setCurrentFloorNumber(plotContainerData.floorNumber)
          }

        if(containerType == "Block"){

          setDisplayCgiImage(true)
          setThisBuildingName(plotContainerData.name)
          
          let tempArray = []

          interactiveRegions.forEach(function (thisRegion){
            let thisId = thisRegion.targetId
            let thisFloor = plotContainerData.plotContainers.find(thisX => thisX.id == thisRegion.targetId)
            //console.log( thisRegion.targetId +' floor number = ' + x.floorNumber)

            // Get plots for this floor
            const plots = thisFloor.plotIds.map((id) => getPlotById(state.plots, id))

            // Include only available plots
            const availablePlots = plots.filter((plot) => plot.plotStatusId === getStatusID(state.plotStatuses, 'Available'))
            const availabilityCount = availablePlots.length || 0

            tempArray.push({
              floorNumber: thisFloor.floorNumber,
              floorLabel: thisFloor.name,
              targetId: thisRegion.targetId,
              availabilityCount: availabilityCount
            })
          })

          const outputElevationArray = [].concat(tempArray).sort((b, a) => parseFloat(a.floorNumber) - parseFloat(b.floorNumber))

          //this is the 1st floor to display
          let startingFloorTargetId = outputElevationArray[outputElevationArray.length -1].targetId

          setFloorsArray(outputElevationArray)
          getPlotContainerDataForId(plotContainerData.plotContainers, startingFloorTargetId)

        }else{
          
          buildBlockView(imageUrl, interactiveRegions)
          setSelectedTargetId(targetId)//to enable hi light of elevation marker [rich 24082021]
        return false
        
        } 
      }
      if (plotContainerData.hasOwnProperty('plotContainers')){
        getPlotContainerDataForId(plotContainerData.plotContainers, targetId)
      }
    })  
  }

  function zoomToPlotMarker() {

    if(state.selectedPlotMarkerId){
      let thisMarker = document.getElementById(state.selectedPlotMarkerId)

      if(transformComponentRef.current !=null){
        const { zoomToElement } = transformComponentRef.current
        let zoomToScale = 2
        if(scale >2 )
        zoomToScale = scale

        zoomToElement( thisMarker , zoomToScale, 3000, 'easeOut')
      }
    } 
  }

  function buildBlockView(imageUrl, interactiveRegionsArray){

    setPlotMarkers([])
    setAptMarkers([])
    let plotRegionsTempArray = []
    let aptRegionsTempArray = []

    if(imageUrl !=null){
      let thisImage = new Image()
      thisImage.onload = loaded
      thisImage.src = imageUrl

      async function loaded() {
        setBlockplanImage(imageUrl)
    
        interactiveRegionsArray.forEach(function (interactiveRegion) {
          
          let posX = interactiveRegion.startX
          let posY = interactiveRegion.startY
          let width = interactiveRegion.width
          let height = interactiveRegion.height
          let targId = interactiveRegion.targetId
          let isAptMarker = interactiveRegion.isTargetAContainer
          
          if(isAptMarker){
            aptRegionsTempArray.push({
              x: posX,
              y: posY,
              id: targId
            })
          }else{
            // let thisPlotInfo = state.plots.find(plotItem => plotItem.id == interactiveRegion.targetId) //show all plot markers
            let thisPlotInfo = state.filteredHomes.find(plotItem => plotItem.id == interactiveRegion.targetId) //show filtered plot markers

            if(thisPlotInfo){
              let thisPlotStatus = state.plotStatuses.find(statusItem => statusItem.id == thisPlotInfo.plotStatusId)
          
              //Think Hub data sometimes has duplicate information (Residence) so only add to array if unique plot id [rich 02082021]
              if(!plotRegionsTempArray.find(thisRegion => thisRegion.id == targId)){

                plotRegionsTempArray.push({
                  x: posX,
                  y: posY,
                  width: width,
                  height: height,
                  id: targId,
                  plotNumber: thisPlotInfo.plotNumber,
                  plotStatus: thisPlotStatus.name,
                  colour: thisPlotStatus.color
                })
              }
            }
          }
        })
        setPlotMarkers(plotRegionsTempArray)
        setAptMarkers(aptRegionsTempArray)
      }
    }
  }

  function DisplayElevation(thisTarget){
    getPlotContainerDataForId(state.plotContainers, thisTarget)
    dispatch({ type: "closePlotPreview" })
  }

  function DisplaySite(){
    let targetID = state.plotContainers[0].id
    if (id != 0)
    targetID = id

    if(state.globalConfigData){
      
      if (state.globalConfigData.spins.includes(targetID.toString())) {
        navigate('/siteplanspin/' + targetID)
      }else{
        //draw content from plot container hub data
        getPlotContainerDataForId(state.plotContainers, targetID)
      }
    }else{
      //draw content from plot container hub data
      getPlotContainerDataForId(state.plotContainers, targetID)
    }
  }

  function ZoomOut(){
    let thisScale = parseFloat(`${scale}`)
    if(thisScale > 1.0)
      thisScale = thisScale - 1.0

    setZoomValue(thisScale)
  }

  function ZoomIn(){
    let thisScale = parseFloat(`${scale}`)
    if(thisScale < 8.0)
      thisScale = thisScale + 1.0

    setZoomValue(thisScale)
  }

  function setZoomValue(thisValue){
    
    const targetScale = parseFloat(thisValue)
    const factor = Math.log(targetScale / scale)
    const { zoomIn, zoomOut } = transformComponentRef.current

    if (targetScale > scale) {
      zoomIn(factor, 300)
    } else {
      zoomOut(-factor, 300)
    }
    setScale(targetScale)
  }

  function CenterView(){
    if(transformComponentRef.current !=null){
      const { centerView } = transformComponentRef.current
      centerView(1, 1000, 'easeOut')
    }
  }

  function StartInteractiveTimeout(){
    clearInterval(interactiveTimer)
    interactiveTimer = setInterval(function() {EnableMarkerInteraction(); }, 200)
  }

  function EnableMarkerInteraction(){
    clearInterval(interactiveTimer)
    dispatch({ type: "isPanning", data: false})
  }

  const mapMargin = state.isMobileDevice ? '2rem' : '4rem'

  return (
    <>
      <Helmet>
        <title>{`Master Plan - ${state.devName.name}`}</title>
      </Helmet>

{(displayBreadcrumbs === true) && 
      <SubMenu>
        <Breadcrumbs />
      </SubMenu>
}

      <Outlet />
      {(isMounted)&&(
      <div className="Masterplan" id="masterplanView">
        <motion.div
          className="Masterplan__Map Masterplan__Map--blockplan"
          key={'MainWrapper'}
          animate={{
            width: state.isPreviewPanelOpen ? `calc(70vw - ${mapMargin})` : `calc(100vw - ${mapMargin})`
          }}
          onAnimationComplete={state.isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 40 }
          }}
        >
          <div className="Masterplan__Info">
            {cgiImage && (
              <img className="Masterplan__Info__Image" src={cgiImage} alt={thisBuildingName}/>
            )}
            <div className="Masterplan__Info__Content">
              {thisBuildingName && (
                <div className="Masterplan__Info__Title">{thisBuildingName}</div>
              )}
              {floorLabel && (
                <div className="Masterplan__Info__Name">{floorLabel}</div>
              )}
              {floorAvailabilityCount > 0 && (
                <AvailabilityBadge count={floorAvailabilityCount} />
              )}
            </div>
          </div>

          {state.filteredHomes.length <= 0 && (
            <Alert variant="floating">No homes match the chosen filters.</Alert>
          )}

          {floorsArray.length > 0 && (
            <div className="Masterplan__ElevationControl">
              {showElevation && (
                <motion.div
                  style={{ originX: 0.95, originY: 0.95 }}
                  initial={{ scale: 0 }}
                  animate={{ y: -15, scale: 1 }}
                  className="Masterplan__ElevationControl__Buttons"
                >
                  {floorsArray.map(thisFloor => {
                    let variant = (thisFloor.availabilityCount > 0) ? 'default' : 'disabled'
                    if (selectedTargetId == thisFloor.targetId) {
                      variant = 'secondary'
                    }

                    return (
                      <MapControl
                        id={thisFloor.targetId}
                        onClick={() => DisplayElevation(`${thisFloor.targetId}`)}
                        key={thisFloor.floorNumber.toString()}
                        variant={variant}
                        disabled={variant === 'disabled'}
                      >
                          {thisFloor.floorNumber === 0 ? 'G' : thisFloor.floorNumber}
                      </MapControl>
                    )
                  })}
                </motion.div>
              )}
              <MapControl
                onClick={() => setShowElevation(!showElevation)}
                variant="layers"
                className={showElevation ? 'active' : ''}
              />
            </div>
          )}

          <TransformWrapper
            ref = {transformComponentRef}
            initialScale={scale}
            centerOnInit
            onZoomStop={(e) => {
                setScale(e.state.scale)
            }}
            onPanning={(e)=>{
              dispatch({ type: "isPanning", data: true})
            }}
            onPanningStop={(e)=>{
              StartInteractiveTimeout()
            }}
            onZoom={(e) => {
              setScale(e.state.scale)
            }}
          >   
            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
              <>
                <div
                  className={`Masterplan__Controls ${(floorsArray.length) ? 'Masterplan__Controls--with-elevation' : ''}`}
                >
                  <MapControl variant="zoom-in" onClick={ZoomIn} title="Zoom in" disabled={scale === 8} />
                  <MapControl variant="zoom-out" onClick={ZoomOut} title="Zoom out" disabled={scale <= 1} />
                  <MapControl variant="center-map" onClick={CenterView} title="Center map" />
                </div>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%"
                  }}
                  >
                  <AnimatePresence  initial={true} cutom={floorDirection} exitBeforeEnter={true}>
                    <motion.div
                      className="zoomContainer"
                      key={blockPlanImage}
                      custom={floorDirection}
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        y: { type: "spring", stiffness: 300, damping: 40 }
                      }}
                    >
                      <img src={blockPlanImage} alt={blockPlanImage} />

                      {aptMarkers.map(aptMarker => (
                        <div key={aptMarker.id.toString()}>
                          <AptMarkerMP
                              containerType={containerType}
                              posX={aptMarker.x}
                              posY={aptMarker.y}
                              id={aptMarker.id}
                            />
                        </div>
                      ))}
                  
                      {plotMarkers.map(plotMarker => (
                        <div key={plotMarker.id.toString()}>
                          <PlotMarkerMP
                            containerType={containerType}
                            posX={plotMarker.x}
                            posY={plotMarker.y}
                            markerWidth={plotMarker.width}
                            markerHeight={plotMarker.height}
                            plotId={plotMarker.id}
                            plotNum={plotMarker.plotNumber}
                            plotColour={plotMarker.colour}
                          />
                        </div>
                      ))}
                    </motion.div>
                  </AnimatePresence>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </motion.div>
        <PlotPreview />
      </div>
      )}
    </>
  )
    
}

export default Masterplan