import React, { useEffect, useContext, useState } from "react"
import Iframe from "../components/Iframe"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"

import { Context } from "../contexts/Store"

import PlotPreview from "../components/PlotPreview"
import SubMenu, { SubMenuSelect } from "../components/SubMenu"
import BackButton from "../components/BackButton"

import "../assets/styles/pages/ExteriorTour.scss"

function ExteriorTour(props) {
  const [state, dispatch] = useContext(Context)

  const { phase, id } = useParams()
  const location = useLocation()
  const previousPath = (location.state) ? location.state.previousPath : ''

  //get the items data from the menu
  const tours = state.menuItems.exteriorTours
  const [currentItem, setCurrentItem] = useState(false)
  const [currentItemKey, setCurrentItemKey] = useState(0)

  function getCurrentItem(itemSlug, items) {
    let item = null
    let itemKey = null

    if (items && items.length > 0) {
      if (itemSlug) {
        items.find((el, key) => {
          if (el.slug === itemSlug) {
            itemKey = key
            item = el
            return
          }
        })

        if (item) {
          //
        } else {
          item = items[0] //default to first
          itemKey = 0
        }
      } else {
        item = items[0] //default to first
        itemKey = 0
      }
    }
    return { itemKey, item }
  }

  useEffect(() => {
    const { itemKey, item } = getCurrentItem(phase, tours)
    setCurrentItem(item)
    setCurrentItemKey(itemKey)
  }, [phase, tours])

  // Close plotPreview on first load
  useEffect(() => {
    dispatch({
      type: 'closePlotPreview'
    })
  }, [])

  /*
  useEffect(() => {
    dispatch({ type: 'closePlotPreview' })
  }, [])
*/
  const navigate = useNavigate()
  const goToRoute = (location) => navigate(location)

  function changeTour(key) {
    let selected = state.menuItems.exteriorTours[key]
    goToRoute(`/exterior-tour/${selected.slug}`)
  }

  const mapMargin = state.isMobileDevice ? '2rem' : '4rem'

  return (
    <div className="ExteriorTour">

      <Helmet>
        <title>{`Exterior Tour - ${state.devName.name}`}</title>
      </Helmet>

      {((state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 1) || previousPath.includes('/siteplanspin')) && (
        <SubMenu>
          {previousPath.includes('/siteplanspin') && (
            <BackButton />
          )}
          {state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 1 && (
            <SubMenuSelect
              list={state.menuItems.exteriorTours}
              value={currentItemKey}
              onChange={(e) => changeTour(e.target.value)}
            ></SubMenuSelect>
          )}
        </SubMenu>
      )}

      {currentItem ? (
        <motion.div
        id = "virtualtour"
          className="Masterplan__Map"
          key={"MainWrapper"}
          animate={{
            width: state.isPreviewPanelOpen && !state.isTabletDevice ? `calc(70vw - ${mapMargin})` : `calc(100vw - ${mapMargin})`
          }}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 40 }
          }}
        >
          <Iframe
            src={
              !id ? currentItem.url : `${currentItem.url}?startscene=pano${id}`
            }
          ></Iframe>
        </motion.div>
      ) : null}

      <PlotPreview />
    </div>
  )
}

export default ExteriorTour
