import React, { useContext } from "react"
import { Link } from "react-router-dom"

import { Context } from "../contexts/Store"
import {
  priceFormatter,
  dynamicSort,
  getStatus,
  findFloorplans
} from "../helpers/utilities"

import AvailabilityBadge from "./AvailabilityBadge"
import FavButton from "../components/FavButton"
import CompareRow from "../components/CompareRow"

import "../assets/styles/components/PlotCompareCard.scss"
import ArrowIcon from "./ArrowIcon"

import ImageZoomButton from "../components/ImageZoomButton"
import { EqualHeightContext } from "react-equal-height"
import Dims from "../components/Dims"

function PlotCompareCard({ item }) {
  const [state, dispatch] = useContext(Context)

  let status = getStatus(state.plotStatuses, "id", item.plotStatusId)

  let images = item.plotType.assets.slice().sort(dynamicSort("order"))

  let image = images[0]

  let floorplans = findFloorplans(images)

  const LoadImage = ({src, alt}) => {
      const { setForceUpdate } = useContext(EqualHeightContext);

      const handleImage = () => (
        setForceUpdate((value) => !value)
      )
      return (
          <img src={src} onLoad={handleImage} alt={alt} />
      )
  }

  return (
    <section className="PlotCompareCard">
      <div className="PlotCompareCard__Header">
        <h2 className="PlotCompareCard__Number">
          <Link to={`/homes/${item.id}`}>Plot {item.plotNumber}</Link>
        </h2>
        <div className="PlotCompareCard__Actions">
          <FavButton plotID={item.id} />
          <Link className="HomeLink" to={`/homes/${item.id}`}>
            <ArrowIcon />
          </Link>
        </div>
      </div>

      <CompareRow name="Image" label={false}>
        {image && (
          <div className="Thumbnail" >
          <LoadImage src={image.fileUrl} alt={image.name} />
          </div>
        )}
      </CompareRow>

      <CompareRow name="Price">
        <strong>
          {status.name === "Available" ? priceFormatter(item.price) : null}
          {status.name === "Unreleased" ? "£POA" : null}
        </strong>
      </CompareRow>

      <CompareRow name="Status">
        <AvailabilityBadge variant="secondary" status={status} />
      </CompareRow>

      <CompareRow name="Bedrooms">{item.plotType.numberOfBeds}</CompareRow>

      <CompareRow name="Type">{item.name}</CompareRow>
      
      <CompareRow name="Floorplans">
        {floorplans.map((image, i) => {
          return (
            <div className="Thumbnail" key={i}>
              <LoadImage src={image.fileUrl} alt={image.name} />
              <div className="Thumbnail__Caption">{image.caption}</div>
              <div className="Thumbnail__Zoom">
                <ImageZoomButton src={image.fileUrl} caption={image.caption} gallery={item.id} />
              </div>
            </div>
          )
        })}
      </CompareRow>

      <CompareRow name="Dimensions">
        <Dims data={item.plotType} compareCard={true} />
      </CompareRow>

    </section>
  )
}

export default PlotCompareCard
