
import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom"
import ScrollToTop from '../components/ScrollToTop'

import Home from './Home'
import Homes from './Homes'
import Plot from './Plot'
import Masterplan from './Masterplan'
import Gallery from './Gallery'
import SiteplanSpin from './SiteplanSpin'
import Favourites from './Favourites'
import Map from './Map'
import ExteriorTour from './ExteriorTour'
import AppSettings from './AppSettings'
import Brochures from './Brochures'
import SyncFavourites from './SyncFavourites'
import Brochure from './Brochure'

function AppRoutes() {
  return (
    <>
    <ScrollToTop />
    <Routes>
      <Route index element={<Home />} />
      <Route path="/masterplan" element={<Masterplan />}>
        <Route path=":id" element={<Masterplan />}>
          <Route path=":id" element={<Masterplan />}>
            <Route path=":id" element={<Masterplan />}/>
          </Route>
        </Route>
      </Route>
      <Route path="/siteplanspin/:id" element={<SiteplanSpin />}>
        <Route path=":id" element={<Masterplan />}>
          <Route path=":id" element={<Masterplan />}>
            <Route path=":id" element={<Masterplan />} />
          </Route>
        </Route>
      </Route>
      <Route path="homes" element={<Homes />} />
      <Route path="/homes/:id" element={<Plot />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="local-area" element={<Map />} />
      <Route path="/favourites" element={<Favourites />} />
      <Route path="/favourites/sync" element={<SyncFavourites />} />
      <Route path="/exterior-tour" element={<ExteriorTour />}>
        <Route path=":phase" element={<ExteriorTour />} >
          <Route path=":id" element={<ExteriorTour />} />
        </Route>
      </Route>
      <Route path="/app-settings/:key" element={<AppSettings />} />
      <Route path="/brochures" element={<Brochures />} />
      <Route path="/brochure" element={<Brochure />} />
    </Routes>
    </>
  )
}

export default AppRoutes
