const initialState = {
  appLoaded: false,
  devId: window['runConfig'].devId,
  devKey: window['runConfig'].devKEY,

  loaded: [],
  error: [],
  allLoaded: false,

  devSpinPath: window['runConfig'].devSpinPath,
  devName: '',
  clientName: '',
  devStyle: {},

  plots: [],
  plotStatuses: [],
  types: [],
  activeType: '',

  filterRoutes: ['/homes', '/compare', '/siteplanspin', '/masterplan'],
  filters: [],
  availableHomes: [],
  filteredHomes: [],
  rangeLabels: [],
  isFiltersOpen: false,

  isMenuOpen: false,
  menuItemsRaw: [],
  menuItems: [],
  plotVRs: false,
  plotContainers: false,
  isPanning: false,
  isPreviewPanelOpen: false,
  selectedPlotMarkerId: false,
  selectedPlotId: false,

  favPlots: localStorage.getItem('favPlots')
    ? JSON.parse(localStorage.getItem('favPlots'))
    : false,
  favPlotsToRemove: [],
  favPlotsToAdd: [],
  favRemoteUpdate: 0,
  showLogin: window['runConfig'].showLogin,
  loggedIn: (localStorage.getItem("user")) ? Boolean(localStorage.getItem("user")) : false,
  user: (localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : {},

  spinMarkerData: [],
  spinConfigData: [],
  globalConfigData: false,
  isVrpersonActive: false,
  currentLight: { ID: null, status: 'off' },

  globalDisclaimer: window['runConfig'].globalDisclaimer,

  environment: window['runConfig'].environment,
  
  spinAssetsForDevice: false,
  isMobileDevice: false,
  isTabletDevice:false,
  supportsWebp: false,
}

export { initialState as initialState }
