import React from 'react'

import '../assets/styles/components/ToggleButton.scss'
import { ReactComponent as IconFilters } from '../assets/img/filter.svg'
import { ReactComponent as IconPOI } from '../assets/img/map-marker-check-light.svg'
import { ReactComponent as IconHeart } from '../assets/img/heart-solid.svg'
import { ReactComponent as IconHeartLight } from '../assets/img/heart-light.svg'
import { ReactComponent as IconClose } from '../assets/img/close.svg'
import { ReactComponent as IconTFL } from '../assets/img/tfl-roundel.svg'
import { ReactComponent as IconArrowLeft } from '../assets/img/long-arrow-left-light.svg'

function ToggleButton({ variant = 'primary', size = 'md', to, onClick, count, active, ...props }) {
  
  let icon
  switch(props.icon) {
    case 'filters':
      icon = <IconFilters />
      break

    case 'poi':
      icon = <IconPOI />
      break

    case 'heart':
      icon = <IconHeart />
      break

    case 'heart-light':
      icon = <IconHeartLight />
      break

    case 'close':
      icon = <IconClose />
      break

    case 'tfl':
      icon = <IconTFL />
      break

      case 'arrowLeft':
        icon = <IconArrowLeft />
        break
  }

  if (!to) {
    return (
      <>
      <button
        className={`ToggleButton ToggleButton--${variant} ToggleButton--${size} ${active ? 'ToggleButton--active' : ''}`}
        onClick={onClick}
      >
        <span className="ToggleButton__Icon">
          {count >= 0 &&
            <span className="ToggleButton__Count">{count}</span>
          }
          {icon}
        </span>
        {props.children && 
          <span className="ToggleButton__Text">
            {props.children}
          </span>
        }
      </button>
      </>
    )
  } 
}

export default ToggleButton
