import React, { useState, useContext, useMemo, useRef, useEffect } from "react"

import { Context } from "../contexts/Store"
import useWebSocket, { ReadyState } from "react-use-websocket"


function LightServer(props) {
  
  const { lightServerAddress } = props

  const [state, dispatch] = useContext(Context)
  const messageHistory = useRef([])

  const { sendMessage, lastMessage, readyState } =
    useWebSocket(lightServerAddress)

  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastMessage),
    [lastMessage]
  )

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated"
  }[readyState]


  useEffect(() => {
    let ID = state.currentLight.ID
    let status = state.currentLight.status
    if (status === "on" && ID) {
      sendMessage(`{"Command": 7, "LightID": ${ID}}`)
    } else if (status === "off" && ID) {
      sendMessage(`{"Command": 1, "LightID": ${ID}}`)
    } else if (status === "attractor" ){
      sendMessage(`{"Command": 4, "LightID": ${ID}}`)
    }
  }, [state.currentLight, sendMessage])

    return null
  }


export default LightServer
