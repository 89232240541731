import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Context } from "../contexts/Store"

function SyncFavourites() {
  const [state, dispatch] = useContext(Context)

  const navigate = useNavigate()
  
  //Set favs from URL
  useEffect(() => {
    const search = window.location.search
    if (search) {
      const params = new URLSearchParams(search)
      const favPlots = (params.get('plots')) ? params.get('plots').split(',') : []
      
      if (favPlots) {
        favPlots.forEach((id, i) => {
          dispatch({
            type: 'addFavPlot',
            data: parseInt(id)
          })
        })
      }
    }
    navigate('/favourites')
  }, [])
  
  return (null)
}
  
export default SyncFavourites
  