import Axios from "axios"

Axios.defaults.baseURL = "https://www.think-hub.co.uk/api"
Axios.defaults.headers.common["Registration-Key"] = window["runConfig"].devKEY
Axios.defaults.headers.post["Content-Type"] = "application/json"

function returnAxiosInstance() {
  return Axios.create()
}

export function get(url) {
  const axios = returnAxiosInstance()
  return axios.get(url)
}

export function getNoHeaders(url) {
  const axios = returnAxiosInstance()
  return axios.get(url, {
    transformRequest: (data, headers) => {
      delete headers.common
      return data
    }
  })
}

export function post(url, requestData) {
  const axios = returnAxiosInstance()
  return axios.post(url, requestData)
}

export function xhrDelete(url, requestData) {
  const axios = returnAxiosInstance()
  return axios.delete(url, { data: requestData })
}
